import React, { useContext, useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Dropdown,
  FormGroup,
  FormLabel,
  Button,
} from "react-bootstrap";
import { MainContext } from "../../App";
import {
  domainCookie,
  getFullName,
  getUrlParamValue,
  goToHref,
  itsMyBirthday,
} from "../../components/global/GlobalTools";
import { useTranslation } from "react-i18next";
import IncomeModule from "../../components/dashboard/admin/IncomeModule";
import DebtsModule from "../../components/dashboard/admin/DebtsModule";
import AverageModule from "../../components/dashboard/admin/AverageModule";
import EventsModule from "../../components/dashboard/admin/EventsModule";
import { GetCyclesFullInfo } from "../../api/Dashboard";
import EnrolledStudentsModule from "../../components/dashboard/admin/EnrolledStudentsModule";
import LeadsModule from "../../components/dashboard/admin/LeadsModule";
import TasksModule from "../../components/dashboard/admin/TasksModule";
import TodayModule from "../../components/dashboard/admin/today/TodayModule";
import OverlayBlockModule from "../../components/dashboard/admin/OverlayBlockModule";
import { GetServices } from "../../api/Organizations";
import Cookies from "js-cookie";
import BirthdayModal from "../../components/global/modals/BirthdayModal";
import * as moment from "moment";

const customStyles = {
  baseContainer: {
    padding: "10px 20px 0px 20px",
  },
  moduleContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  moduleContainerFixedHeight: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
    height: "350px",
  },
};

const prefix = process.env.REACT_APP_PREFIX;

const DashboardAdminView = () => {
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation([
    "class",
    "global",
    "modalCreabaseContainerudent",
    "cycles",
    "dashboard",
    "reports",
  ]);
  const [orgInfo, setOrgInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [cycles, setCycles] = useState([]);
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [groups, setGroups] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [levelPrograms, setLevelPrograms] = useState([]);
  const [filters, setFilters] = useState({
    cycle: null,
    level: null,
    grade: null,
    group: null,
    programs: null,
    levelProgram: null,
  });
  const [modulesActive, setModulesActive] = useState({});
  const [readyFilters, setReadyFilters] = useState(false);
  // lazy loading for modules
  const [incomeLoadingData, setIncomeLoadingData] = useState(true);
  const [debtsLoadingData, setDebtsLoadingData] = useState(true);
  const [averageLoadingData, setAverageLoadingData] = useState(true);
  const [leadsLoadingData, setLeadsLoadingData] = useState(true);
  const [tasksLoadingData, setTasksLoadingData] = useState(true);
  const [eventLoadingData, setEventLoadingData] = useState(true);
  const [enrolledLoadingData, setEnrolledLoadingData] = useState(true);
  const [accessLogLoadingData, setAccessLogLoadingData] = useState(true);
  const [showBirthdayModal, setShowBirthdayModal] = useState(false);
  const [birthdayBoys, setBirthdayBoys] = useState([]);
  const [nameBirthdayBoys, setNameBirthdayBoys] = useState("");
  const [widthSize, setWidthSize] = useState(window.innerWidth);
  const [mobileSize] = useState(1024);
  /**
   * Obtains the cycles, levels, grades and groups
   */
  const getCyclesFullInformation = () => {
    GetCyclesFullInfo().then((result) => {
      let cycles = [];
      if (result.status) {
        cycles = result.data;
        let currentCycle = false;
        const today = moment().unix();
        for (let cycle of cycles) {
          if (
            today > cycle.start_time &&
            today < cycle.end_time &&
            cycle.current_cycle == 1
          ) {
            currentCycle = cycle;
          }
        }
        if (!currentCycle) {
          currentCycle = cycles.find((cycle) => cycle.current_cycle === "1");
        }
        let newFilters = { ...filters };
        // check if there are filters stored in the localstorage and if they belong to the user
        const filtersDashboard = JSON.parse(
          localStorage.getItem(`cmFiltersDashboard${prefix}`)
        );
        if (
          filtersDashboard &&
          filtersDashboard.userId == Cookies.get(`cmUserID${prefix}`)
        ) {
          setLevels(filtersDashboard.school_levels || []);
          setGrades(filtersDashboard.grades || []);
          setGroups(filtersDashboard.groups || []);
          setPrograms(filtersDashboard.programs || []);
          setLevelPrograms(filtersDashboard.program_levels || []);
          newFilters = filtersDashboard.filters;
        } else {
          newFilters.cycle = currentCycle;
          setLevels(currentCycle?.school_levels || []);
        }
        setFilters(newFilters);
      }
      setCycles(cycles);
      setReadyFilters(true);
    });
  };

  /**
   * Changes the value of the selected filters
   * @param {object} value
   * @param {string} filterType
   */
  const selectFilters = (value, filterType) => {
    setReadyFilters(false);
    let newFilters = { ...filters };
    const updateFiltersDashbaord = JSON.parse(
      localStorage.getItem(`cmFiltersDashboard${prefix}`)
    ) || {
      userId: Cookies.get(`cmUserID${prefix}`),
    };
    localStorage.removeItem(`cmFiltersDashboard${prefix}`);
    newFilters[filterType] = value;
    switch (filterType) {
      case "cycle":
        setLevels(value.school_levels || []);
        updateFiltersDashbaord.school_levels = value.school_levels || [];
        newFilters.level = null;
        newFilters.grade = null;
        newFilters.group = null;
        newFilters.programs = null;
        newFilters.levelProgram = null;
        //clean selector data
        setGrades([]);
        setPrograms([]);
        setGroups([]);
        setLevelPrograms([]);
        //refresh modules
        modulesActive.Payments && setIncomeLoadingData(true);
        modulesActive.Payments && setDebtsLoadingData(true);
        modulesActive.SCE && setAverageLoadingData(true);
        if (modulesActive.Payments || modulesActive.SCE || modulesActive.App) {
          setEnrolledLoadingData(true);
        }
        modulesActive.CRM && setLeadsLoadingData(true);
        modulesActive.CRM && setTasksLoadingData(true);
        if (modulesActive.SCE || modulesActive.App) {
          setAccessLogLoadingData(true);
        }
        break;
      case "level":
        setGrades(value?.grades || []);
        setPrograms(value?.programs || []);
        updateFiltersDashbaord.grades = value?.grades || [];
        updateFiltersDashbaord.programs = value?.programs || [];
        newFilters.grade = null;
        newFilters.group = null;
        newFilters.programs = null;
        newFilters.levelProgram = null;
        //clean selector data
        setGroups([]);
        setLevelPrograms([]);
        //refresh modules
        modulesActive.Payments && setIncomeLoadingData(true);
        modulesActive.Payments && setDebtsLoadingData(true);
        modulesActive.SCE && setAverageLoadingData(true);
        modulesActive.CRM && setLeadsLoadingData(true);
        modulesActive.CRM && setTasksLoadingData(true);
        break;
      case "grade":
        setGroups(value?.groups || []);
        updateFiltersDashbaord.groups = value?.groups || [];
        newFilters.group = null;
        newFilters.programs = null;
        newFilters.levelProgram = null;
        //refresh modules
        modulesActive.Payments && setIncomeLoadingData(true);
        modulesActive.Payments && setDebtsLoadingData(true);
        modulesActive.SCE && setAverageLoadingData(true);
        break;
      case "group":
        //refresh modules
        modulesActive.Payments && setIncomeLoadingData(true);
        modulesActive.Payments && setDebtsLoadingData(true);
        modulesActive.SCE && setAverageLoadingData(true);
        break;
      case "programs":
        setLevelPrograms(value?.program_levels || []);
        updateFiltersDashbaord.program_levels = value?.program_levels || [];
        newFilters.grade = null;
        newFilters.group = null;
        newFilters.levelProgram = null;
        //refresh modules
        modulesActive.Payments && setIncomeLoadingData(true);
        modulesActive.Payments && setDebtsLoadingData(true);
        modulesActive.SCE && setAverageLoadingData(true);
        break;
      case "levelProgram":
        //refresh modules
        modulesActive.Payments && setIncomeLoadingData(true);
        modulesActive.Payments && setDebtsLoadingData(true);
        modulesActive.SCE && setAverageLoadingData(true);
        break;

      default:
    }
    setFilters(newFilters);
    // Update dashboard filters
    updateFiltersDashbaord.filters = newFilters;
    localStorage.setItem(
      `cmFiltersDashboard${prefix}`,
      JSON.stringify(updateFiltersDashbaord)
    );
    setTimeout(() => setReadyFilters(true), 500);
  };

  const loadLocalData = () => {
    let orgInfo = null;
    let userInfo = null;
    orgInfo = JSON.parse(localStorage.getItem(`cmOrganizationInfo${prefix}`));
    localStorage.getItem(`cmOrganizationID${prefix}`);
    userInfo = JSON.parse(localStorage.getItem(`cmUserProfile${prefix}`));
    let differentOrganization =
      localStorage.getItem(`cmOrganizationID${prefix}`) != orgInfo?.id;
    let noDabaBase = orgInfo == null || userInfo == null;
    if (noDabaBase || differentOrganization) {
      setTimeout(() => loadLocalData(), 500);
    } else {
      setOrgInfo(orgInfo);
      setUserInfo(userInfo);
      validateBirthday(userInfo);
    }
  };

  const loadOrganizationServices = () => {
    let orgServices = {};
    GetServices(Cookies.get(`cmOrganizationID${prefix}`)).then((response) => {
      if (response.status) {
        let servicesUpdated = {};
        for (let service of response.data) {
          switch (service.service_id) {
            case "1": {
              //SIE
              servicesUpdated.SIE = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "2": {
              //Website
              servicesUpdated.Website = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "3": {
              //Payments
              servicesUpdated.Payments = {
                id: service.service_id,
                active: service.premium == "0" ? false : true,
              };
              break;
            }
            case "4": {
              //App
              servicesUpdated.App = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "5": {
              //Reviews
              servicesUpdated.Reviews = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "6": {
              //Chat
              servicesUpdated.Chat = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "7": {
              //DocuSing
              servicesUpdated.DocuSing = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "8": {
              //SCE
              servicesUpdated.SCE = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "9": {
              //CRM
              servicesUpdated.CRM = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "10": {
              //DigitalContent
              servicesUpdated.DigitalContent = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
            case "11": {
              //GoogleForEducations
              servicesUpdated.GoogleForEducations = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
              };
              break;
            }
          }
        }
        for (let item in servicesUpdated) {
          orgServices[item] = servicesUpdated[item].active;
        }
      } else {
        let services = JSON.parse(localStorage.getItem(`${prefix}Services`));
        for (let item in services) {
          orgServices[item] = services[item].active;
        }
      }
    });
    setModulesActive(orgServices);
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    loadLocalData();
    getCyclesFullInformation();
    loadOrganizationServices();
  }, []);

  /**
   * Evaluate if a user has a birthday
   */
  const validateBirthday = (userInfo) => {
    // comprobate if the user has a birthday
    let birthdayBoys = [];
    if (itsMyBirthday(userInfo.birth_date)) {
      birthdayBoys.push({
        id: userInfo.id,
        name: getFullName(userInfo),
        firstName: userInfo.first_name.split(" ")[0],
      });
    }
    // comprobate if the user has a student with birthday
    if (userInfo.student && Array.isArray(userInfo.student)) {
      for (const student of userInfo.student) {
        // filters out students who have a birthday and are at a basic level
        if (
          student.its_basic_level == "1" &&
          itsMyBirthday(student.birth_date)
        ) {
          const user = {
            id: student.id,
            name: getFullName(student),
            firstName: student.first_name.split(" ")[0],
          };
          birthdayBoys.push(user);
        }
      }
    }
    setBirthdayBoys(birthdayBoys);
    // save the first name of those who have birthdays
    const nameBirthdayBoys = birthdayBoys
      .map((person) => person.firstName)
      .join(", ");
    setNameBirthdayBoys(nameBirthdayBoys);
  };

  // Show modal when you have a birthday boy and come from login
  useEffect(() => {
    if (
      nameBirthdayBoys &&
      (Cookies.get(`cmSourceView${prefix}`) === "login" ||
        getUrlParamValue("show") === "birthday")
    ) {
      toggleBirthdayModal();
      // Clean cookie
      Cookies.remove(`cmSourceView${prefix}`, {
        domain: domainCookie(),
        path: "/",
      });
      // Clean url param
      history.replaceState(
        null,
        document.title,
        location.origin + location.pathname
      );
    }
  }, [nameBirthdayBoys]);

  /**
   * Function to show or hide BirtdayModal
   */
  const toggleBirthdayModal = () => {
    setShowBirthdayModal((status) => !status);
  };

  /**
   * Purpose: Detect the current screen size and update the state variable
   */
  window.onresize = () => {
    setWidthSize(window.innerWidth);
  };

  return permissions.admin_dashboard?.access ? (
    <Container fluid>
      <Row>
        <Col className="fw-bolder fs-2" md={12}>
          {orgInfo?.name}
          {nameBirthdayBoys && (
            <Button
              onClick={toggleBirthdayModal}
              size="lg"
              style={{
                backgroundColor: "#10B981",
                color: "#fff",
                marginLeft: "20px",
              }}
              variant="outline-light"
            >
              <i className="fal fa-birthday-cake fa-lg" />
              {t("global:birthdayModal.todayIsYouBirthday", {
                name: nameBirthdayBoys,
              })}
            </Button>
          )}
        </Col>
      </Row>
      {Object.entries(modulesActive).length > 0 && (
        <Row>
          <Col md={10} className="border-end">
            <Row>
              <Col md={5}>
                <p
                  className="fs-5 text-capitalize"
                  style={{ marginTop: "20px", paddingRight: "0px" }}
                >
                  {t("dashboard:labels.welcome")}
                  {userInfo?.first_name?.toLowerCase()}{" "}
                  {userInfo?.last_name?.toLowerCase()}
                </p>
              </Col>
              {/* School cycle filters  */}
              <Col
                sm={12}
                md={7}
                className={
                  widthSize > mobileSize
                    ? "d-flex flex-row-reverse bd-highlight"
                    : "d-flex flex-column-reverse bd-highlight"
                }
              >
                {filters?.level && filters.level.type == "higher" ? (
                  <>
                    <FormGroup className="ms-3">
                      <FormLabel>{t("modalImport.level")} </FormLabel>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="levelProgram"
                          variant="outline-secondary"
                          disabled={!filters?.programs}
                        >
                          {filters?.levelProgram
                            ? filters.levelProgram.name
                            : t("dashboard:placeholderText.textAllLevels")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            key={"allProgramLevels"}
                            onClick={() => selectFilters(null, "levelProgram")}
                          >
                            {t("dashboard:placeholderText.textAllLevels")}
                          </Dropdown.Item>
                          {levelPrograms.map((levelProgram) => {
                            return (
                              <Dropdown.Item
                                key={levelProgram.value}
                                onClick={() =>
                                  selectFilters(levelProgram, "levelProgram")
                                }
                              >
                                {levelProgram.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </FormGroup>
                    {/* Academic Offer */}
                    <FormGroup className="ms-3">
                      <FormLabel>
                        {t("modalImport.academicOffering")}{" "}
                      </FormLabel>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="academicOffer"
                          variant="outline-secondary"
                          disabled={!filters?.level}
                        >
                          {filters?.programs
                            ? filters.programs.name
                            : t("dashboard:placeholderText.textAllOffers")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            key="allOffers"
                            onClick={() => selectFilters(null, "programs")}
                          >
                            {t("dashboard:placeholderText.textAllOffers")}
                          </Dropdown.Item>
                          {programs.map((programs) => {
                            return (
                              <Dropdown.Item
                                key={programs.value}
                                onClick={() =>
                                  selectFilters(programs, "programs")
                                }
                              >
                                {programs.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </FormGroup>
                  </>
                ) : (
                  <>
                    <FormGroup className="ms-3">
                      <FormLabel>{t("table.group")}</FormLabel>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="group"
                          variant="outline-secondary"
                          disabled={!filters?.grade}
                        >
                          {filters?.group
                            ? filters.group.name
                            : t("dashboard:placeholderText.textAllGroup")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            key="allGroups"
                            onClick={() => selectFilters(null, "group")}
                          >
                            {t("dashboard:placeholderText.textAllGroup")}
                          </Dropdown.Item>
                          {groups.map((group) => {
                            return (
                              <Dropdown.Item
                                key={group.value}
                                onClick={() => selectFilters(group, "group")}
                              >
                                {group.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </FormGroup>
                    <FormGroup className="ms-3">
                      <FormLabel> {t("table.grade")}</FormLabel>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="grade"
                          variant="outline-secondary"
                          disabled={!filters?.level}
                        >
                          {filters?.grade
                            ? filters.grade.name
                            : t("dashboard:placeholderText.textAllGrades")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            key="allGrades"
                            onClick={() => selectFilters(null, "grade")}
                          >
                            {t("dashboard:placeholderText.textAllGrades")}
                          </Dropdown.Item>
                          {grades.map((grade) => {
                            return (
                              <Dropdown.Item
                                key={grade.value}
                                onClick={() => selectFilters(grade, "grade")}
                              >
                                {grade.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </FormGroup>
                  </>
                )}
                <FormGroup className="ms-3">
                  <FormLabel>{t("global:levelSchool")} </FormLabel>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="schoolLevel"
                      variant="outline-secondary"
                      disabled={!filters?.cycle}
                    >
                      {filters?.level
                        ? filters.level.name
                        : t("dashboard:placeholderText.textAllLevels")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        key="allLevels"
                        onClick={() => selectFilters(null, "level")}
                      >
                        {t("dashboard:placeholderText.textAllLevels")}
                      </Dropdown.Item>
                      {levels.map((level) => {
                        return (
                          <Dropdown.Item
                            key={level.value}
                            onClick={() => selectFilters(level, "level")}
                          >
                            {level.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormGroup>

                <FormGroup className="ms-3">
                  <FormLabel>{t("dashboard:labels.schoolCycle")}</FormLabel>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="schoolCycle"
                      variant="outline-secondary"
                      className="text-truncate"
                    >
                      {filters?.cycle
                        ? filters.cycle.name
                        : t("cycles:select.placeholder")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {cycles.map((cycle) => {
                        return (
                          <Dropdown.Item
                            key={cycle.value}
                            onClick={() => selectFilters(cycle, "cycle")}
                          >
                            {cycle.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={7} style={customStyles.baseContainer}>
                <OverlayBlockModule
                  active={modulesActive.Payments}
                  module="payments"
                  content={
                    <Row style={customStyles.moduleContainerFixedHeight}>
                      <Col sm={12} md={6} className="border-end">
                        <IncomeModule
                          waitingData={incomeLoadingData}
                          setWaitinData={setIncomeLoadingData}
                          readyFilters={readyFilters}
                          filters={filters}
                          active={modulesActive.Payments}
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <DebtsModule
                          waitingData={debtsLoadingData}
                          setWaitinData={setDebtsLoadingData}
                          readyFilters={readyFilters}
                          filters={filters}
                          active={modulesActive.Payments}
                        />
                      </Col>
                    </Row>
                  }
                />
              </Col>
              <Col sm={12} md={5} style={customStyles.baseContainer}>
                <OverlayBlockModule
                  active={modulesActive.App || modulesActive.Website}
                  module="events"
                  customWidth="104%"
                  content={
                    <Row>
                      <Col
                        md={12}
                        style={customStyles.moduleContainerFixedHeight}
                      >
                        <EventsModule
                          waitingData={eventLoadingData}
                          setWaitinData={setEventLoadingData}
                          readyFilters={readyFilters}
                          filters={filters}
                          active={modulesActive.App || modulesActive.Website}
                        />
                      </Col>
                    </Row>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={7} style={customStyles.baseContainer}>
                <OverlayBlockModule
                  active={modulesActive.SCE}
                  module="average"
                  content={
                    <Row style={customStyles.moduleContainer}>
                      <Col md={12}>
                        <AverageModule
                          waitingData={averageLoadingData}
                          setWaitinData={setAverageLoadingData}
                          readyFilters={readyFilters}
                          filters={filters}
                          active={modulesActive.SCE}
                          cycles={cycles}
                        />
                      </Col>
                    </Row>
                  }
                />
                <OverlayBlockModule
                  active={
                    modulesActive.Payments ||
                    modulesActive.SCE ||
                    modulesActive.App
                  }
                  module="students"
                  content={
                    <Row className="mt-2" style={customStyles.moduleContainer}>
                      <Col md={12}>
                        <EnrolledStudentsModule
                          waitingData={enrolledLoadingData}
                          setWaitinData={setEnrolledLoadingData}
                          readyFilters={readyFilters}
                          filters={filters}
                          active={
                            modulesActive.Payments ||
                            modulesActive.SCE ||
                            modulesActive.App
                          }
                        />
                      </Col>
                    </Row>
                  }
                />
              </Col>
              <Col md={5} style={customStyles.baseContainer}>
                <OverlayBlockModule
                  active={modulesActive.CRM}
                  module="crm"
                  customWidth="104%"
                  content={
                    <Row>
                      <Col md={12} style={customStyles.moduleContainer}>
                        <LeadsModule
                          waitingData={leadsLoadingData}
                          setWaitinData={setLeadsLoadingData}
                          readyFilters={readyFilters}
                          filters={filters}
                          active={modulesActive.CRM}
                        />
                        <br />
                        <TasksModule
                          waitingData={tasksLoadingData}
                          setWaitinData={setTasksLoadingData}
                          readyFilters={readyFilters}
                          filters={filters}
                          active={modulesActive.CRM}
                        />
                      </Col>
                    </Row>
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} style={customStyles.baseContainer}>
            <TodayModule
              modulesActive={modulesActive}
              readyFilters={readyFilters}
              filters={filters}
              accessLogLoadingData={accessLogLoadingData}
              setAccessLogLoadingData={setAccessLogLoadingData}
            />
          </Col>
        </Row>
      )}
      {showBirthdayModal && (
        <BirthdayModal
          showBirthdayModal={showBirthdayModal}
          toggleBirthdayModal={toggleBirthdayModal}
          birthdayBoys={birthdayBoys}
        />
      )}
    </Container>
  ) : (
    goToHref("/landing_page")
  );
};

export default DashboardAdminView;
