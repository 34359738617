import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserInfo from "../../components/dashboard/UserInfo";
import ClassListView from "../../components/dashboard/ClassListView";
import EventsView from "../../components/dashboard/EventsView";
import NewsView from "../../components/dashboard/NewsView";
import StudentsAttendance from "../../components/dashboard/StudentsAttendance";
import { GetServices } from "../../api/Organizations";
import Cookies from "js-cookie";
import OverlayBlockModule from "../../components/dashboard/admin/OverlayBlockModule";
import { servicesFormat } from "../../components/dashboard/Tools";
import BirthdayModal from "../../components/global/modals/BirthdayModal";
import {
  domainCookie,
  getFullName,
  getUrlParamValue,
  itsMyBirthday,
} from "../../components/global/GlobalTools";

const customStyles = {
  textWelcome: {
    color: "#5E5E5E",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "24px",
  },
  baseContainer: {
    padding: "10px 16px 0px 20px",
  },
  moduleContainer: {
    background: "#fff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "16px",
    position: "relative",
  },
  moduleContainerNews: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    height: "570px",
  },
  moduleContainerEvents: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    marginBottom: "16px",
    height: "308px",
  },
};

const DashboardStaff = () => {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["dashboard", "dashboardStudent"]);
  const [modulesActive, setModulesActive] = useState(null);
  const [showBirthdayModal, setShowBirthdayModal] = useState(false);
  const [birthdayBoys, setBirthdayBoys] = useState([]);
  const [nameBirthdayBoys, setNameBirthdayBoys] = useState("");

  /**
   * Purpose: Load the organization's services
   */
  const loadOrganizationServices = () => {
    let orgServices = {};
    GetServices(Cookies.get(`cmOrganizationID${prefix}`))
      .then((response) => {
        if (response.status) {
          // format the services
          orgServices = servicesFormat(response.data);
        } else {
          // recover the services from localstorage
          let services = JSON.parse(localStorage.getItem(`${prefix}Services`));
          for (let item in services) {
            orgServices[item] = services[item].active;
          }
        }
      })
      .finally(() => {
        setModulesActive(orgServices);
      });
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    getUserData();
    loadOrganizationServices();
  }, []);

  const getUserData = () => {
    let user = null;
    user = JSON.parse(localStorage.getItem(`cmUserProfile${prefix}`));
    if (!user) {
      setTimeout(() => getUserData(), 200);
    } else {
      validateBirthday(user);
    }
  };

  /**
   * Evaluate if a user has a birthday
   */
  const validateBirthday = (userInfo) => {
    // comprobate if the user has a birthday
    let birthdayBoys = [];
    if (itsMyBirthday(userInfo.birth_date)) {
      birthdayBoys.push({
        id: userInfo.id,
        name: getFullName(userInfo),
        firstName: userInfo.first_name.split(" ")[0],
      });
    }
    // comprobate if the user has a student with birthday
    if (userInfo.student && Array.isArray(userInfo.student)) {
      for (const student of userInfo.student) {
        // filters out students who have a birthday and are at a basic level
        if (
          student.its_basic_level == "1" &&
          itsMyBirthday(student.birth_date)
        ) {
          const user = {
            id: student.id,
            name: getFullName(student),
            firstName: student.first_name.split(" ")[0],
          };
          birthdayBoys.push(user);
        }
      }
    }
    setBirthdayBoys(birthdayBoys);
    // save the first name of those who have birthdays
    const nameBirthdayBoys = birthdayBoys
      .map((person) => person.firstName)
      .join(", ");
    setNameBirthdayBoys(nameBirthdayBoys);
  };

  // Show modal when you have a birthday boy and come from login
  useEffect(() => {
    if (
      nameBirthdayBoys &&
      (Cookies.get(`cmSourceView${prefix}`) === "login" ||
        getUrlParamValue("show") === "birthday")
    ) {
      toggleBirthdayModal();
      // Clean cookie
      Cookies.remove(`cmSourceView${prefix}`, {
        domain: domainCookie(),
        path: "/",
      });
      // Clean url param
      history.replaceState(
        null,
        document.title,
        location.origin + location.pathname
      );
    }
  }, [nameBirthdayBoys]);

  /**
   * Function to show or hide BirtdayModal
   */
  const toggleBirthdayModal = () => {
    setShowBirthdayModal((status) => !status);
  };

  return (
    <Container fluid>
      {/** Header */}
      <Row>
        <Col md={5}>
          <div style={customStyles.textWelcome}>{t("labels.Welcome")}</div>
        </Col>
        <Col md={7}>
          {nameBirthdayBoys && (
            <Button
              onClick={toggleBirthdayModal}
              size="lg"
              style={{
                backgroundColor: "#10B981",
                color: "#fff",
              }}
              variant="outline-light"
            >
              <i className="fal fa-birthday-cake fa-lg" />
              {t("dashboardStudent:birthdayModal.todayIsYouBirthday", {
                name: nameBirthdayBoys,
              })}
            </Button>
          )}
        </Col>
      </Row>
      {/** Modules */}
      {modulesActive != null && (
        <Row>
          {/** ---- FIRST COLUMN --- */}
          <Col xs={12} xxl={8} style={customStyles.baseContainer}>
            <Row style={{ height: "100px", ...customStyles.moduleContainer }}>
              <Col md={12}>
                <UserInfo />
              </Col>
            </Row>
            <OverlayBlockModule
              active={modulesActive.SCE}
              textButton={t("dashboardStudent:textModals.textSeeMore")}
              originRequest="attendance"
              nameService="SCE"
              customWidth="102.2%"
              content={
                <Row
                  style={{ height: "371px", ...customStyles.moduleContainer }}
                >
                  <Col md={12}>
                    <StudentsAttendance active={modulesActive.SCE} />
                  </Col>
                </Row>
              }
            />
            <OverlayBlockModule
              active={modulesActive.SCE}
              textButton={t("dashboardStudent:textModals.textSeeMore")}
              originRequest="classes"
              nameService="SCE"
              customWidth="102.2%"
              content={
                <Row
                  style={{ height: "391px", ...customStyles.moduleContainer }}
                >
                  <Col md={12}>
                    <ClassListView active={modulesActive.SCE} />
                  </Col>
                </Row>
              }
            />
          </Col>
          {/** ---- SECOND COLUMN --- */}
          <Col xs={12} xxl={4} style={customStyles.baseContainer}>
            <Row>
              <Col xs={12}>
                <OverlayBlockModule
                  active={modulesActive.App}
                  textButton={t("dashboardStudent:textModals.textSeeMore")}
                  originRequest="events_and_news"
                  nameService="App"
                  customWidth={"105%"}
                  content={
                    <Row style={customStyles.moduleContainerEvents}>
                      <Col sm={12} md={12} xl={12}>
                        <EventsView active={modulesActive.App} />
                      </Col>
                    </Row>
                  }
                />
              </Col>
              <Col xs={12}>
                <OverlayBlockModule
                  active={modulesActive.App}
                  textButton={t("dashboardStudent:textModals.textSeeMore")}
                  originRequest="events_and_news"
                  nameService="App"
                  new={"new"}
                  customWidth={"105%"}
                  content={
                    <Row style={customStyles.moduleContainerNews}>
                      <Col sm={12} md={12} xl={12}>
                        <NewsView sizeNews={true} active={modulesActive.App} />
                      </Col>
                    </Row>
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {showBirthdayModal && (
        <BirthdayModal
          showBirthdayModal={showBirthdayModal}
          toggleBirthdayModal={toggleBirthdayModal}
          birthdayBoys={birthdayBoys}
        />
      )}
    </Container>
  );
};

export default DashboardStaff;
