import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SeeAllModule from "./admin/SeeAllModule";

const customStyles = {
  styleImg: {
    height: "100px",
    width: "100px",
    border: "solid 1px white",
  },
  lazy: {
    height: "25px",
  },
  lazyImgRelative: {
    height: "107px",
    borderRadius: "75px",
    width: "114px",
  },
  containerInfo: {
    height: "116px",
  },
};

const lazyLoadingStudentInRelative = () => {
  return (
    <div>
      <Row className="table-lazyLoading">
        <Col md={3}>
          <div style={customStyles.lazyImgRelative}></div>
        </Col>
        <Col md={9}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={8}>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-3">
        <Col md={2} className="offset-md-10">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};

const StudentInfo = (props) => {
  const [t] = useTranslation([
    "dashboardStudent",
    "organizations",
    "crm",
    "class",
    "dashboard",
  ]);
  const [loadingStudent, setLoadingStudent] = useState(true);
  const [data, setData] = useState({});

  /**
   * Initial Loading
   */
  useEffect(() => {
    if (props.studentSelected.id) {
      setData(props.studentSelected);
      setLoadingStudent(false);
    }
  }, [props.studentSelected]);

  return (
    <Row>
      <Col md={12} className="mt-3">
        {loadingStudent ? (
          lazyLoadingStudentInRelative()
        ) : (
          <>
            <Row>
              <Col md={3}>
                <img
                  src={
                    data.image_url
                      ? data.image_url
                      : `${process.env.PUBLIC_URL}/img/student_placeholder.svg`
                  }
                  className="rounded-circle img-fluid"
                  style={customStyles.styleImg}
                ></img>
              </Col>
              <Col md={9}>
                <Row>
                  <b className="fs-3">{data.name}</b>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={customStyles.containerInfo}>
                <Row>
                  <p>
                    <b>{t("organizations:modalCreate.email")}: </b>
                    {data.email}
                  </p>
                </Row>
                <Row>
                  <Col md={2}>
                    <p>
                      <b>{t("class:table.grade")}: </b>
                    </p>
                  </Col>
                  <Col md={2}>
                    <b>{t("class:table.group")}: </b>
                  </Col>
                  <Col md={2}>
                    <b>{t("class:modalImport.level")}: </b>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <p>{data.grade}</p>
                  </Col>
                  <Col md={2}>
                    <p>{data.group}</p>
                  </Col>
                  <Col md={8}>
                    <p>{data.school_level}</p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <SeeAllModule
                  isCMv1={true}
                  customText={t("dashboard:labels.viewProfile")}
                  path={`/estudiante/${props.studentSelected.id}`}
                ></SeeAllModule>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default StudentInfo;

StudentInfo.propTypes = {
  studentSelected: PropTypes.object,
};
