/**
 *       **** Readme ****
 *
 * Component: <Table />
 * Purpose: Contains only the structure for the table, receives the header columns and the data that the table will display. Also include the functionality to print the report.
 *
 * Props:
 *   -columns: {Object} Specifies the header titles of the columns
 *   -data: {Object} Specifies the values of each column
 *        -Include the property notSelectable{Boolan} with true value in the item to disable row selection.
 *   -summation: {Boolean} Make the total sum of the column (default value: false)
 *   -loading: {Boolean} Show the lazy loader of the rows
 *   -globalFilterDate: {Boolean} Show global date filter
 *   -setExportData: (Export to Excel) Gets the data to be exported to Excel
 *   -setPrintExport: {Boolean} Disable the print and export buttons if the search did not return results
 *   -rowSelect: {Boolean} Show column with checkbox in header and table data
 *   -rowOnclick: {Function} Function that is executed when clicking on a row of the table.
 *                This function is defined in the file that calls the <Table /> component and contains whatever procedures you want to perform.
 *                It receives as a parameter the object of the record on which the click was made.
 *   -selectAllRows: {Object} It is used to check all boxes with their parameters. Value is used to select and deselect all, Swith invokes on the effect.
 *   -cleanRequestFilters: {Function} Clears the request filters of the parent component when the table filters are connected to the api.
 *   -filters: {Array} Specifies the values of the filters that the table should have by default if they exist.
 *   -unrelatedFilters: {Bool} Indicates if the filters in the table are not related to each other and work independently.
 *   -showColumnGear:{Bool} Indicates when the column editing gear will be displayed.
 *
 *   These props are used to use the "Edit Columns" modal
 *   -hiddenColumns: {Array} (Edit columns) Array with the id/accessor of the columns that are hidden by default (default value: [])
 *   -showEditColumns: {Boolean} (Edit columns) Flag for the toogle of the modal "Edit Columns"
 *   -setShowEditColumns: {Function} (Edit columns) Toggle function for the "Edit Columns" modal
 *   -limitRowsShown: {string} limits the rows displayed in the table
 *   -setRowsDisplayed: {function} set the number of rows displayed in the table
 *
 * Columns Array Properties:
 *    --Filter: The elements created are,
 *      - InputFilter, It's the text filter
 *      - SelectFilter, It's the selector filter
 *    --Summation: Make the total sum of the column, the property that is indicated for the sum belongs to the arrangement that the mobile campus service returns
 *    --propertySummation: It is the property in the object "rows.original" that is indicated to do the sum of the column. This prop is required when the "Summation" prop is set to "true"
 *
 *
 * Creation date: 06/December/2021
 * Last update: 04/April/2022
 */

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
  usePagination,
} from "react-table";
import { Row, Col /*, Badge*/ } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment/locale/es";
import {
  thousandsFormat,
  imgOrganization,
} from "../../components/global/GlobalTools";

/** Components */
import GlobalSelectFilter from "./GlobalSelectFilter";
import { LoadingTableBody, CleanFilters } from "../lazyLoading/LazyLoading";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import ModalEditColumns from "./ModalEditColumns";
import InputFilter from "./InputFilter";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the Table)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const Table = React.forwardRef((props, ref) => {
  const name = Table.displayName || Table.name;
  React.forwardRef.displayName = `logProps(${name})`;
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["table", "crm"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [language] = useState(localStorage.getItem(`cmLanguage${prefix}`));
  const [hiddenColumns, setHiddenColumns] = useState(
    props.hiddenColumns ? props.hiddenColumns : []
  );
  const [filters] = useState(props.filters ? props.filters : []);
  const [summation] = useState(props.summation ? props.summation : false);
  const [columns, setColumns] = useState(props.columns);
  const [urlImgOrganization, setUrlImgOrganization] = useState("");
  const [printAll, setPrintAll] = useState(null);
  const cleanRequestFilters = props.cleanRequestFilters
    ? props.cleanRequestFilters
    : false;
  const [unrelatedFilters, setUnrelatedFilters] = useState(
    props.unrelatedFilters ? props.unrelatedFilters : true
  );
  let data = props.data;
  const defaultColumn = useMemo(() => {
    return {
      Filter: InputFilter,
    };
  }, []);
  const selectAllRows = props.selectAllRows
    ? props.selectAllRows
    : {
        switch: false,
        value: false,
      };
  const showColumnGear =
    props.showColumnGear != "undefined" ? props.showColumnGear : true;
  const limitRowsShown = props.limitRowsShown ? props.limitRowsShown : false;

  /**
   * Purpose: It is a custom filter that does filtering by date range
   * @param {Array} rows
   * @param {String} id
   * @param {Object} filterValues {startDate, endDate, value, label}
   * @returns Array with the results of the date range
   */
  function dateBetweenFilterFn(rows, id, filterValues) {
    // Validate if not a date range is sent
    if (!filterValues.start_date) {
      return rows;
    }
    let start = moment
      .unix(filterValues.startDate)
      .subtract(1, "d")
      .format("YYYY/MM/DD");
    let end = moment
      .unix(filterValues.endDate)
      .add(1, "d")
      .format("YYYY/MM/DD");

    return (rows = rows.filter((val) => {
      return moment(val.original[id], "DD/MM/YYYY").isBetween(start, end);
    }));
  }

  dateBetweenFilterFn.autoRemove = (val) => !val;

  /**
   * Description: Rows filter by start of the string
   * @param {array} rows
   * @param {number} startid
   * @param {string} filterValue
   * @return {array} rows filtered
   */
  function InputFilterFn(rows, id, filterValue) {
    return (rows = rows.filter((row) => {
      const value = row.original[id];
      const filter = filterValue.toLowerCase();
      return value && value.toLowerCase().startsWith(filter);
    }));
  }

  InputFilterFn.autoRemove = (val) => !val;

  /**Initial loading */
  useEffect(() => {
    setUnrelatedFilters(props.unrelatedFilters);
  }, [props.unrelatedFilters]);

  /** Purpose: Add custom filter types */
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      dateBetween: dateBetweenFilterFn /*<- LIKE THIS*/,
      inputFilter: InputFilterFn /*<- LIKE THIS*/,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    selectedFlatRows,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    getToggleAllRowsSelectedProps,
    state,
    state: { selectedRowIds, pageIndex },
    setGlobalFilter,
    setAllFilters,
    toggleAllRowsSelected,
    isAllRowsSelected,
    page,
    pageOptions,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetFilters: false,
      autoResetSelectedRows: true,
      unrelatedFilters,
      initialState: {
        hiddenColumns: hiddenColumns,
        columns: columns,
        filterTypes: filterTypes,
        filters: filters,
        unrelatedFilters: props.unrelatedFilters,
        pageSize: 50,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (props.rowSelect) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: `selection`,
            minWidth: 5,
            width: 25,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div className="row-select" style={{ width: "35px" }}>
                <IndeterminateCheckbox
                  index={`row-${row.original.index}`}
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  const { globalFilter } = state;

  let firstPageRows = rows;

  if (limitRowsShown) {
    firstPageRows = page.slice(0, limitRowsShown);
  }

  /**
   * Description: Effect to set value when all selected or unselected
   */
  useEffect(() => {
    if (props.setIsAllRowsSelected) {
      props.setIsAllRowsSelected(isAllRowsSelected);
    }
  }, [isAllRowsSelected, selectedFlatRows]);

  /**
   * Description: Effect to select and deselect all checkbox
   */
  useEffect(() => {
    if (props.selectAllRows != undefined) {
      toggleAllRowsSelected(props.selectAllRows.value);
    }
  }, [selectAllRows.switch]);

  /*
   * Description: Get column filter
   */
  useEffect(() => {
    if (props.setColumnsFilter) {
      props.setColumnsFilter(state.filters);
    }
  }, [state]);

  /**
   * Purpose: Gets the rows to be exported
   * @param {Object} rowValues
   * @returns row array
   */
  const getRowValues = (rowValues) => {
    let rowValue = [];
    Object.keys(rowValues).forEach((prop) => {
      //Only export the columns that are visible is validated
      if (!hiddenColumns.includes(prop)) {
        rowValue.push(rowValues[prop]);
      }
    });
    return rowValue;
  };

  /**
   * Purpose: Export to Excel
   */
  const dataForExcel = () => {
    //Get the headers and Summations
    if (rows.length) {
      let values = [];
      let titles = rows[0].allCells;
      let rowTitles = [];
      let rowSummation = [];
      if (titles) {
        for (let i = 0; i < titles.length; i++) {
          if (titles[i].column) {
            //Only export the columns that are visible is validated
            if (
              !hiddenColumns.includes(titles[i].column.id) &&
              titles[i].column.id != "selection"
            ) {
              rowTitles.push(titles[i].column.Header);

              if ({}.propertyIsEnumerable.call(titles[1].column, "Summation")) {
                if (titles[i].column.Summation) {
                  let additionColumn = rows.reduce(
                    (sum, row) =>
                      parseFloat(
                        row.original[titles[i].column.propertySummation]
                      ) + sum,
                    0
                  );
                  rowSummation.push(`$ ${thousandsFormat(additionColumn)}`);
                } else {
                  rowSummation.push("");
                }
              }
            }
          }
        }
        props.setPrintExport(!rowTitles.length);
        values.push(rowTitles);
        if (rowSummation.length) {
          values.push(rowSummation);
        }

        /** Get the values */
        if (Object.keys(selectedFlatRows).length !== 0) {
          //selectedFlatRows Contains the arrays of the rows that have been selected
          selectedFlatRows.forEach((original) => {
            let rowValue = getRowValues(original.values);
            values.push(rowValue);
          });
        } else {
          for (let i = 0; i < rows.length; i++) {
            let rowValue = getRowValues(rows[i].values);
            values.push(rowValue);
          }
        }
        //Object to export the data
        let data = [
          {
            name: language == "spanish" ? "Reporte" : "Report", // Sheet name
            from: {
              array: values, // array of arrays
            },
          },
        ];
        props.setExportData(data);
      }
    }
  };

  /**Initial loading */
  useEffect(() => {
    //Context: Get organization image url
    let urlImg = imgOrganization(null, false);
    if (urlImg && urlImg._currentValue) {
      setUrlImgOrganization(urlImg._currentValue.imgOrganizationUrl);
    }
    if (props.showEditColumns) {
      props.setShowEditColumns(props.showEditColumns);
    }
  }, [props.showEditColumns]);

  /**Update selected records */
  useEffect(() => {
    if (props.setSelectedRowIds) {
      props.setSelectedRowIds(selectedFlatRows);
    }
    if (props.setRowsDisplayed) {
      props.setRowsDisplayed(rows.length);
    }
    getToggleAllRowsSelectedProps(false);
  }, [rows]);

  /**Initial loading */
  useEffect(() => {
    if (props.setCounterSelectedStudents) {
      props.setCounterSelectedStudents(selectedFlatRows.length);
    }
    if (props.setExportData) {
      dataForExcel();
    }
    if (props.rowIsSelect) {
      if (selectedFlatRows.length > 0) {
        props.rowIsSelect(true);
      } else {
        props.rowIsSelect(false);
      }
    }

    if (props.setValues) {
      props.setValues(rows);
    }
    let print = rows.find((row) => row.isSelected === true);
    setPrintAll(true);
    if (print) {
      setPrintAll(false);
    }
  }, [rows, hiddenColumns, columns, selectedRowIds, selectedFlatRows]);

  useEffect(() => {
    let isEmpty = Object.entries(selectedRowIds).length === 0;
    if (isEmpty === true && props.setSelectedRowIds) {
      props.setSelectedRowIds([]);
    } else if (
      isEmpty === false &&
      props.setSelectedRowIds &&
      selectedFlatRows.length
    ) {
      //If at least one record has been selected, then...
      props.setSelectedRowIds(selectedFlatRows);
    }
  }, [selectedRowIds]);

  return (
    <>
      {props.globalFilterDate ? (
        <div className="content-header">
          <Row>
            <Col xs={12} sm={4}></Col>
            <Col xs={12} sm={4}>
              {/** Evaluate if the global filter is shown */}
              <GlobalSelectFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
                t={t}
              />
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      <div className="content-table">
        <ModalEditColumns
          setShowEditColumns={props.setShowEditColumns}
          showEditColumns={props.showEditColumns}
          allColumns={allColumns}
          IndeterminateCheckbox={
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
          }
          hiddenColumns={hiddenColumns}
          setHiddenColumns={setHiddenColumns}
          setColumns={setColumns}
          columns={columns}
          t={t}
        />
        <div ref={ref}>
          {urlImgOrganization ? (
            <Row className="print-source m-0">
              <Col xs={props.headerInformation ? 2 : 12} className="img p-2">
                <img
                  src={urlImgOrganization}
                  style={{ width: "150px", marginBottom: "20px" }}
                />
              </Col>
              {props.headerInformation && (
                <Col xs={9} md={10} className="p-3">
                  <>{props.headerInformation()}</>
                </Col>
              )}
            </Row>
          ) : (
            ""
          )}
          <table id={props.id} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, thIndex) => (
                <tr key={thIndex} {...headerGroup.getHeaderGroupProps()}>
                  <th
                    style={
                      firstPageRows.length
                        ? { width: "10px", padding: "12px" }
                        : { width: "10px", padding: "26px" }
                    }
                    className="first-col"
                  ></th>
                  {headerGroup.headers.map((column, cIndex) => (
                    <th
                      key={cIndex}
                      id={cIndex}
                      className={props.rowSelect ? "row-select" : ""}
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth || "100px",
                          width: cIndex === 0 ? "35px" : column.width, //when cIndex is === 0 the th corresponds to the gear icon to be kept at 35px
                          maxWidth: column.maxWidth || "150px",
                          position: "relative",
                        },
                      })}
                    >
                      {/** Sort */}
                      {cIndex == 0 && showColumnGear ? (
                        <div
                          className="settings-table"
                          onClick={() => props.setShowEditColumns(true)}
                        >
                          <i className="bi bi-gear"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      <p
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="title-header"
                      >
                        {column.render("Header")}
                        {(cIndex == 0 && !showColumnGear) ||
                          (!column.hideSort && (
                            <span
                              style={{
                                float: "right",
                                paddingRight: "6px",
                                width: "14px",
                                display: "inline-table",
                              }}
                              className="arrow-sorted"
                            >
                              {" "}
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <img
                                    src={`${process.env.PUBLIC_URL}/img/down-arrow.svg`}
                                    style={{ height: "13px" }}
                                  />
                                ) : (
                                  <img
                                    src={`${process.env.PUBLIC_URL}/img/up-arrow.svg`}
                                    style={{ height: "13px" }}
                                  />
                                )
                              ) : (
                                <img
                                  src={`${process.env.PUBLIC_URL}/img/down-up-arrow.svg`}
                                  style={{
                                    height: "13px",
                                    display: column.disableSortBy
                                      ? "none"
                                      : "inline-block",
                                  }}
                                />
                              )}
                            </span>
                          ))}
                      </p>
                      {/** Filters */}
                      {!column.hideFilter && (
                        <div
                          className="element-filter"
                          style={{ paddingBottom: summation ? 0 : "49px" }}
                        >
                          {/* Show only the checkbox for the students table view */}
                          {cIndex == 0 &&
                            props.enableCheckToSelectAllRecords && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "29px",
                                  right: "11px",
                                  width: "13px",
                                  zIndex: "2",
                                }}
                              >
                                <input
                                  id="checkbox"
                                  type="checkbox"
                                  onClick={() => props.selectAllRecords()}
                                  style={{
                                    accentColor: "#ea2c54",
                                    cursor: "pointer",
                                  }}
                                  checked={isAllRowsSelected}
                                />
                              </div>
                            )}
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      )}

                      {/** Summation */}
                      {summation ? (
                        <div className="element-summation">
                          {column.Summation ? column.render("Summation") : null}
                        </div>
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {props.loading ? (
                <tr style={{ backgroundColor: "#ffffff" }}>
                  <td colSpan={12}>
                    <LoadingTableBody />
                  </td>
                </tr>
              ) : (
                (firstPageRows.length > 0 &&
                  firstPageRows.map((row, i) => {
                    let classPrint = printAll
                      ? ""
                      : row.isSelected
                      ? ""
                      : "no-print";
                    prepareRow(row);
                    return (
                      <tr key={i} {...row.getRowProps()} className={classPrint}>
                        <td
                          style={{ width: "10px", padding: "12px" }}
                          className="first-col"
                        ></td>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              {...cell.getCellProps()}
                              className={props.rowSelect ? "row-select" : ""}
                              style={
                                index == 1
                                  ? {
                                      wordBreak: "break-word",
                                      cursor:
                                        props.rowOnclick &&
                                        !row.original.notSelectable
                                          ? "pointer"
                                          : "default",
                                      minWidth: cell.column.minWidth,
                                      width: cell.column.width,
                                      maxWidth: cell.column.maxWidth,
                                    }
                                  : index == 0 && props.rowSelect
                                  ? {
                                      cursor:
                                        props.rowOnclick &&
                                        !row.original.notSelectable
                                          ? "pointer"
                                          : "default",
                                      minWidth: "25px",
                                      width: "30px",
                                      maxWidth: "35px",
                                    }
                                  : {
                                      cursor:
                                        props.rowOnclick &&
                                        !row.original.notSelectable
                                          ? "pointer"
                                          : "default",
                                      minWidth: cell.column.minWidth,
                                      width: cell.column.width,
                                      maxWidth: cell.column.maxWidth,
                                    }
                              }
                              onClick={() =>
                                props.rowOnclick &&
                                index > 0 &&
                                !row.original.notSelectable
                                  ? props.rowOnclick(row.original, state)
                                  : null
                              }
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })) || (
                  <tr style={{ backgroundColor: "#ffffff" }}>
                    <td colSpan={12} style={{ textAlign: "center" }}>
                      <CleanFilters
                        setAllFilters={setAllFilters}
                        cleanRequestFilters={cleanRequestFilters}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      {limitRowsShown && (
        <div className="pagination">
          <i
            className="bi bi-chevron-left"
            style={{
              cursor: "pointer",
              background: "#e5e5e5",
              marginRight: "5px",
              borderRadius: "4px",
            }}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          ></i>{" "}
          <span>
            <strong>
              {t("page")} {pageIndex + 1} {t("of")} {pageOptions.length}
            </strong>{" "}
          </span>
          <i
            className="bi bi-chevron-right"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            style={{
              cursor: "pointer",
              background: "#e5e5e5",
              marginLeft: "5px",
              borderRadius: "4px",
            }}
          ></i>{" "}
        </div>
      )}
    </>
  );
});

Table.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  hiddenColumns: PropTypes.array,
  hasIndex: PropTypes.bool,
  loading: PropTypes.bool,
  summation: PropTypes.bool,
  globalFilterDate: PropTypes.bool,
  rowSelect: PropTypes.bool,
  setExportData: PropTypes.func,
  setPrintExport: PropTypes.func,
  showEditColumns: PropTypes.bool,
  setShowEditColumns: PropTypes.func,
  setSelectedRowIds: PropTypes.func,
  row: PropTypes.object,
  rowIsSelect: PropTypes.func,
  setValues: PropTypes.array,
  filters: PropTypes.array,
  rowOnclick: PropTypes.func,
  cleanRequestFilters: PropTypes.func,
  unrelatedFilters: PropTypes.bool,
  selectAllRows: PropTypes.object,
  setIsAllRowsSelected: PropTypes.func,
  setColumnsFilter: PropTypes.func,
  showColumnGear: PropTypes.bool,
  setCounterSelectedStudents: PropTypes.func,
  limitRowsShown: PropTypes.number,
  setRowsDisplayed: PropTypes.func,
  headerInformation: PropTypes.func,
  enableCheckToSelectAllRecords: PropTypes.bool,
  selectAllRecords: PropTypes.func,
};
