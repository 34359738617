import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Spinner } from "react-bootstrap";
import { goTo, validateUserRoleIds } from "../global/GlobalTools";

export default function ModalServicePremium(props) {
  let CM_beta = window.location.href.includes("//beta.app.campusmovil.com");
  let CM_staging = window.location.href.includes(
    "//staging.app.campusmovil.com"
  );
  let CM_live = window.location.href.includes("//app.campusmovil.com");
  let campusMovil = CM_beta ? true : CM_staging ? true : CM_live ? true : false;

  return (
    <div>
      <Modal
        show={props.modalPremium}
        size="lg"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title-center text-center">
            {props.typeOfService == "7"
              ? props.t("premiumServices.payments")
              : props.typeOfService == "6"
              ? props.t("premiumServices.reviews")
              : props.typeOfService == "9"
              ? props.t("premiumServices.crm")
              : props.typeOfService == "10"
              ? props.t("premiumServices.chat")
              : ""}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body mbottom10">
          <Row>
            <Col xs={12} style={{ marginTop: "20px" }}>
              <div
                style={{
                  width: "fit-content",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div style={{ width: "fit-content", display: "inline-block" }}>
                  {props.typeOfService == "7" ? (
                    <img
                      src={
                        campusMovil
                          ? `${process.env.PUBLIC_URL}/img/FremiumModalsIcons/Payments.svg`
                          : `${process.env.PUBLIC_URL}/img/FremiumModalsIcons/Payments-uplift.svg`
                      }
                    />
                  ) : props.typeOfService == "6" ? (
                    <img
                      src={
                        campusMovil
                          ? `${process.env.PUBLIC_URL}/img/FremiumModalsIcons/resenas.svg`
                          : `${process.env.PUBLIC_URL}"/img/FremiumModalsIcons/resenas-uplift.svg`
                      }
                    />
                  ) : props.typeOfService == "9" ? (
                    <img
                      src={
                        campusMovil
                          ? `${process.env.PUBLIC_URL}/img/FremiumModalsIcons/crm.svg`
                          : `${process.env.PUBLIC_URL}/img/FremiumModalsIcons/crm-uplift.svg`
                      }
                    />
                  ) : props.typeOfService == "10" ? (
                    <img
                      src={
                        campusMovil
                          ? `${process.env.PUBLIC_URL}/img/FremiumModalsIcons/chat.svg`
                          : `${process.env.PUBLIC_URL}"/img/FremiumModalsIcons/chat-uplift.svg`
                      }
                      style={{ width: "120px" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ width: "fit-content", display: "inline-block" }}>
                  <span className="premiumText-informationModal-LP">
                    <i
                      className="fas fa-crown"
                      style={{ color: "#ffb300" }}
                    ></i>
                    Premium
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} style={{ marginTop: "20px" }}>
              <div
                style={{
                  marginTop: "20px",
                  display: "grid",
                  fontSize: "16px",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <div>
                    <p style={{ textAlign: "center" }}>
                      {props.t("modalServicePremium.unlock")}
                      {props.typeOfService == "7" ? (
                        <span>
                          <b> {props.t("premiumServices.payments")} </b>
                        </span>
                      ) : props.typeOfService == "6" ? (
                        <span>
                          <b> {props.t("premiumServices.reviews")} </b>
                        </span>
                      ) : props.typeOfService == "9" ? (
                        <span>
                          <b> {props.t("premiumServices.crm")} </b>
                        </span>
                      ) : props.typeOfService == "10" ? (
                        <span>
                          <b> {props.t("premiumServices.chat")} </b>
                        </span>
                      ) : (
                        ""
                      )}
                      <span>{props.t("modalServicePremium.getMore")}</span>
                    </p>
                  </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                  {props.typeOfService == "7" ? (
                    <div>
                      <p>
                        <i
                          className="fas fa-check"
                          style={{ marginRight: "10px" }}
                        ></i>
                        {props.t("modalServicePremium.text1")}
                      </p>

                      <p>
                        <i
                          className="fas fa-check"
                          style={{ marginRight: "10px" }}
                        ></i>
                        {props.t("modalServicePremium.text2")}
                        <br />
                      </p>

                      <p>
                        <i
                          className="fas fa-check"
                          style={{ marginRight: "10px" }}
                        ></i>
                        {props.t("modalServicePremium.text3")}
                        <br />
                      </p>
                    </div>
                  ) : props.typeOfService == "6" ? (
                    <div>
                      <p>
                        <i
                          className="fas fa-check"
                          style={{ marginRight: "10px" }}
                        ></i>
                        {props.t("modalServicePremium.text4")}
                        <br />
                      </p>

                      <p>
                        <i
                          className="fas fa-check"
                          style={{ marginRight: "10px" }}
                        ></i>
                        {props.t("modalServicePremium.text5")}
                        <br />
                      </p>

                      <p>
                        <i
                          className="fas fa-check"
                          style={{ marginRight: "10px" }}
                        ></i>
                        {props.t("modalServicePremium.text6")}
                        <br />
                      </p>
                    </div>
                  ) : props.typeOfService == "9" ? (
                    ""
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => props.setModalPremium(false)}
            disabled={props.isSaving}
          >
            {props.t("modalServicePremium.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={
              validateUserRoleIds([1,2], null, "&&", true)
                ? () => props.modalNotificationSend(true)
                : () =>
                    goTo(
                      campusMovil
                        ? "https://www.campusmovil.com.mx/solicitar-demo/"
                        : "https://www.uplifteducational.com/"
                    )
            }
            disabled={props.isSaving}
          >
            <p style={{ marginBottom: 0 }}>
              {validateUserRoleIds([1,2], null, "&&", true)
                ? props.t("modalServicePremium.contactCollege")
                : props.t("modalServicePremium.knowMore")}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={
                  props.isSaving
                    ? { marginLeft: "5px", display: "inline-block" }
                    : { marginLeft: "5px", display: "none" }
                }
              />
            </p>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalServicePremium.propTypes = {
  modalPremium: PropTypes.bool,
  group: PropTypes.string,
  typeOfService: PropTypes.string,
  isSaving: PropTypes.bool,
  setModalPremium: PropTypes.func,
  modalNotificationSend: PropTypes.func,
  t: PropTypes.func,
};
