//Libraries
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
  Alert,
  Form as FormB,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MainContext } from "../../../App";
import { randomCode } from "../../../components/global/GlobalTools";
import * as moment from "moment";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

//Custom components
import FieldInput from "../../../components/global/form/FieldInput";
import FormSelect from "../../../components/global/form/FormSelect";
import FormCheck from "../../../components/global/form/FormCheck";
import TextLoading from "../../../components/global/TextLoading";
import CreateStudentModal from "../../../components/students/modals/CreateStudentModal";
import { DatePicker } from "../../../components/global/form/DatePicker";

// API Services
import {
  GetStudentsReduced,
  getRegimenFiscal,
} from "../../../api/StudentsReport";

import {
  createRelativePost,
  getRelationship,
  addRelationship,
} from "../../../api/relatives";

const relativeInfo = {
  first_name: "",
  last_name: "",
  email: "",
  password: randomCode(), //randomCode(), meter en global el danromCode para generee la constraseña
  test_account: false,
  relative_id: "",
  student_ids: [],
  rfc_receiver: "XAXX010101000",
  receiver_name: "PUBLICO EN GENERAL",
  zip_code: "01000",
  //user_id: "0",
  regimen_selected: {
    value: "12",
    label: "616 - Sin obligaciones fiscales",
  },
  phone: "",
  is_emergency_contact: false,
};

const relationshipInfo = {
  name: "",
  creatingRelationship: false,
};
export default function ModalCreateRelative(props) {
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation(["modalCreateRelative", "global", "crm"]);
  const [students, setStudents] = useState([]);
  const [initialValues, setInitialValues] = useState(relativeInfo);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [relationsOptions, setRelationsOptions] = useState([]);
  const [showModalCreateStudent, setShowModalCreateStudent] = useState(false);
  const [typePassword, setTypePassword] = useState("text");
  const [regimenRfc, setRegimenRfc] = useState([]);
  const [error, setError] = useState(false);
  const [newRelationship, setNewRelationship] = useState(relationshipInfo);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [disabledCreateRelative, setDisabledCreateRelative] = useState(false);
  const [disabledCheck, setDisabledCheck] = useState(true);
  const prefix = process.env.REACT_APP_PREFIX;
  const language = localStorage.getItem(`cmLanguage${prefix}`);

  //Traslate calendar
  registerLocale("spanish", es);
  let Locallanguage = {};
  if (language === "spanish") {
    Locallanguage = { locale: "spanish" };
  }

  //Gets Students
  const getStudentsReduced = () => {
    GetStudentsReduced().then((result) => {
      let data = result.data.data;
      let students = data;
      students = students.map((item) => {
        return {
          id: item.id,
          label:
            item.first_name + " " + item.middle_name + " " + item.last_name,
          value: item.id,
        };
      });
      setStudents(students);
    });
  };
  //Bring the student relations
  const getStudentRelationShips = () => {
    getRelationship().then((result) => {
      let data = result.data;
      let relations = [];
      for (let item of data) {
        if (item.active == 1) {
          relations.push({
            label: item.name,
            value: item.id,
          });
        }
      }
      let optionAdd = {
        label: props.language === "spanish" ? "Agregar Otro" : "Add other",
        value: "other",
      };
      relations.push(optionAdd);
      setRelationsOptions(relations);
    });
  };

  const newRelationshipStudents = (setFieldValue) => {
    let relationship = { ...newRelationship };
    relationship.creatingRelationship = true;
    setNewRelationship(relationship);
    addRelationship({ name: relationship.name }).then((result) => {
      let data = result.data;
      let otherRelationship = {
        label: data.name,
        value: data.id,
      };
      let allRelationOptions = [...relationsOptions];
      let positionOther = allRelationOptions.length - 1;
      allRelationOptions.splice(positionOther, 0, otherRelationship);
      setRelationsOptions(allRelationOptions);
      relationship.creatingRelationship = false;
      setNewRelationship(relationship);
      setFieldValue("relative_id", otherRelationship);
    });
  };

  const getRegimen = () => {
    getRegimenFiscal().then((result) => {
      let data = result.data;
      let allRfc = data.map((item) => {
        return {
          id: item.id,
          label: item.regimen_fiscal + " - " + item.description,
          value: item.id,
          moralPerson: item.moral_person,
          physicalPerson: item.physical_person,
        };
      });
      let rfcs = [
        {
          label: "Personas Físicas",
          options: allRfc.filter((item) => item.physicalPerson == 1),
        },
        {
          label: "Personas Morales",
          options: allRfc.filter((item) => item.moralPerson == 1),
        },
      ];
      setRegimenRfc(rfcs);
    });
  };

  const addRfc = (event) => {
    let relative = { ...initialValues };
    relative.regimen_selected = event;
    setInitialValues(relative);
  };

  const createRelative = (values) => {
    setDisabledCreateRelative(true);
    const request = createRequest({ ...values });
    createRelativePost(request).then((result) => {
      if (result.error) {
        setError(result.error.description);
      } else {
        props.updateList();
        closeModal();
      }
      setDisabledCreateRelative(false);
    });
  };

  const createRequest = (values) => {
    let request = {};
    request.first_name = values.first_name.toUpperCase();
    request.last_name = values.last_name.toUpperCase();
    request.test_account = values.test_account;
    request.relative_id = values.relative_id.value;
    request.student_ids = selectedStudents.map((student) => student.id);
    values.email ? (request.email = values.email) : null;
    values.password ? (request.password = values.password) : null;
    request.user_rfcs = [
      {
        rfc_receiver: values.rfc_receiver,
        receiver_name: values.receiver_name,
        zip_code: values.zip_code,
        regimen_fiscal_id: values.regimen_selected?.value,
      },
    ];
    if (values.phone && values.phone != "") {
      request.phone = values.phone;
      request.is_emergency_contact = values.is_emergency_contact;
    }
    values.birthDate
      ? (request.birth_date = moment(values.birthDate)
          .utc()
          .startOf("day")
          .unix())
      : null;
    return request;
  };

  /**
   *
   * @param {array} studentsArray //It is an array of student objects
   */
  const addStudents = (studentsArray) => {
    setSelectedStudents(studentsArray);
    setInputDisabled(studentsArray.length == 0); //Desabilita los demas input's cuando no tenga un estudiante seleccionado
  };

  const showHide = () => {
    let typeInput = typePassword === "password" ? "text" : "password";
    setTypePassword(typeInput);
  };

  const closeModal = () => {
    setInitialValues(relativeInfo);
    setSelectedStudents([]);
    props.setShowModalCreateReleative(false);
    setInputDisabled(true);
    setError(false);
  };

  useEffect(() => {
    getStudentsReduced();
    getStudentRelationShips();
    getRegimen();
  }, []);

  const tooltipStudent = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t("labels.placeholderAdd")}
      </Tooltip>
    );
  };

  const tooltipPassword = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t("global:tooltipGlobal.textPassword")}
      </Tooltip>
    );
  };

  const studentCreated = (student = false) => {
    if (student) {
      let newStudent = {
        id: student.id,
        label:
          student.first_name +
          " " +
          (student.middle_name || "") +
          " " +
          student.last_name,
        value: student.id,
      };
      let allStudents = [...students];
      allStudents.unshift(newStudent);
      let studentsSelected = [...selectedStudents];
      studentsSelected.push(newStudent);
      setInputDisabled(studentsSelected.length == 0);
      setSelectedStudents(studentsSelected);
      setStudents(allStudents);
    }
    setShowModalCreateStudent(false);
    props.setShowModalCreateReleative(true);
  };

  /**
   * Validate fields
   */
  const validateSchema = Yup.object().shape({
    student_ids: Yup.array().required(
      t("modalCreateRelative:labels.studentsRequired")
    ),
    relative_id: Yup.object().required(
      t("modalCreateRelative:labels.studentRelationship") +
        " " +
        t("modalCreateRelative:labels.required")
    ),
    first_name: Yup.string().required(
      t("modalCreateRelative:labels.name") +
        " " +
        t("modalCreateRelative:labels.required")
    ),
    last_name: Yup.string().required(
      t("modalCreateRelative:labels.lastName") +
        " " +
        t("modalCreateRelative:labels.required")
    ),
    password: Yup.string("")
      .required(
        t("modalCreateRelative:labels.password") +
          " " +
          t("modalCreateRelative:labels.required")
      )
      .test(
        "formatPassword",
        t("global:tooltipGlobal.textPassword"),
        (password) => {
          if (password) {
            let passwordRegex = new RegExp(
              /(?=^(?:[^A-Z]*[A-Z]))(?=^(?:[^a-z]*[a-z]))(?=^(?:\D*\d))(?=^(?:\w*\W))^[A-Za-z\d\W]{8,}$/
            );
            return passwordRegex.test(password);
          }
        }
      ),
    phone: Yup.string().test(
      "phoneFormat",
      t("global:validations.invalidPhoneFormat"),
      (value) => {
        if (value) {
          let phoneRegex = new RegExp(/^[0-9]+$/);
          return phoneRegex.test(value) || value == "";
        }
        return true;
      }
    ),
  });

  return (
    <div>
      <Modal
        show={props.showModalCreateReleative}
        size="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("modalCreateRelative:labels.modalTitle")}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            createRelative(values);
          }}
        >
          {(form) => (
            <Form>
              <ModalBody className="modal-body pt-0">
                {/* Select your student */}
                <Row>
                  <Col md={9}>
                    <FormSelect
                      label={t("modalCreateRelative:labels.selectStudents")}
                      name="student_ids"
                      placeholder={t(
                        "modalCreateRelative:labels.placeholderSelectStudents"
                      )}
                      options={students}
                      onChange={(e) => {
                        addStudents(e);
                        form.setFieldError(!selectedStudents.length);
                      }}
                      required={true}
                      isMulti
                      value={selectedStudents}
                    />
                  </Col>
                  {permissions.students_list.edit && (
                    <Col
                      md={3}
                      style={{
                        textAlign: "end",
                        paddingTop: "30px",
                      }}
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={tooltipStudent}
                      >
                        <Card.Link
                          md={3}
                          className={"link-add-student"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowModalCreateStudent(true);
                            props.setShowModalCreateReleative(false);
                          }}
                        >
                          {t("modalCreateRelative:labels.add")}
                          <i className="far fa-user-plus"></i>
                        </Card.Link>
                      </OverlayTrigger>
                    </Col>
                  )}
                </Row>
                {/* Relationship with the student */}
                <Row>
                  <Col md={12}>
                    <FormSelect
                      label={t(
                        "modalCreateRelative:labels.studentRelationship"
                      )}
                      placeholder={t("modalCreateRelative:labels.search")}
                      name="relative_id"
                      options={relationsOptions}
                      onChange={(e) => {
                        form.setFieldValue("relative_id", e);
                      }}
                      value={form.values.relative_id}
                      isDisabled={inputDisabled}
                    />
                  </Col>
                </Row>
                {/* Add another relationship */}
                {form.values.relative_id.value === "other" && (
                  <Row>
                    <Col md={9}>
                      <FieldInput
                        label={t("labels.addOther")}
                        capitalize={true}
                        name="otherRelation"
                        type="text"
                        disabled={inputDisabled}
                        onChange={(e) => {
                          newRelationship.name = e.target.value;
                          setNewRelationship(newRelationship);
                        }}
                      />
                    </Col>
                    <Col md={3} className="px-4">
                      <Button
                        style={{ marginTop: "36px" }}
                        variant="primary"
                        onClick={() =>
                          newRelationshipStudents(form.setFieldValue)
                        }
                        disabled={newRelationship.creatingRelationship}
                      >
                        {t("global:buttons.add")}
                      </Button>
                    </Col>
                  </Row>
                )}
                {/* Name */}
                <Row>
                  <Col>
                    <FieldInput
                      label={t("modalCreateRelative:labels.name")}
                      capitalize={true}
                      name="first_name"
                      type="text"
                      required={true}
                      disabled={inputDisabled}
                    />
                  </Col>
                </Row>
                {/* Last Name */}
                <Row>
                  <Col>
                    <FieldInput
                      label={t("modalCreateRelative:labels.lastName")}
                      capitalize={true}
                      name="last_name"
                      type="text"
                      placeholder=""
                      required={true}
                      disabled={inputDisabled}
                    />
                  </Col>
                </Row>
                {/* Birth Date */}
                <Row>
                  <Col>
                    <DatePicker
                      label={t("modalCreateRelative:labels.dateBirth")}
                      placeholderText="DD/MM/YYYY"
                      dateFormat="dd/MM/yyyy"
                      name="birthDate"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      {...Locallanguage}
                      maxDate={new Date()}
                    />
                  </Col>
                </Row>
                {/* Number Phone & Emergency Contact*/}
                <Row>
                  <Col md={12}>
                    <FieldInput
                      label={t("crm:modalAddProspect.phoneNumer")}
                      capitalize={true}
                      name="phone"
                      type="phone"
                      placeholder=""
                      maxlength="10"
                      disabled={inputDisabled}
                      onInput={(input) => {
                        if (input.target.value == "") {
                          form.setFieldValue("is_emergency_contact", false);
                        }
                        setDisabledCheck(!input.target.value.length);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {disabledCheck ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip
                            className="color-white"
                            id="tooltipEmergyContact"
                          >
                            {t("modalCreateRelative:labels.textTooltip")}
                          </Tooltip>
                        }
                      >
                        <span className="d-inline-block">
                          <FormCheck
                            name="is_emergency_contact"
                            label={t(
                              "modalCreateRelative:labels.emergyContact"
                            )}
                            type="checkbox"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                            disabled={disabledCheck}
                          />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <FormCheck
                        name="is_emergency_contact"
                        label={t("modalCreateRelative:labels.emergyContact")}
                        type="checkbox"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                        disabled={disabledCheck}
                      />
                    )}
                  </Col>
                </Row>
                {/* Email */}
                <Row>
                  <Col>
                    <FieldInput
                      label={t("modalCreateRelative:labels.email")}
                      capitalize={true}
                      name="email"
                      type="text"
                      placeholder=""
                      disabled={inputDisabled}
                    />
                  </Col>
                </Row>
                {/* Password */}
                <Row>
                  <Col md={11}>
                    <FormB.Group className="mb-3">
                      <FormB.Label>
                        {t("modalCreateRelative:labels.password")}
                        <OverlayTrigger
                          placement="top"
                          overlay={tooltipPassword}
                        >
                          <span
                            className="bi bi-question-circle-fill"
                            aria-hidden="true"
                            style={{
                              verticalAlign: "middle",
                              cursor: "pointer",
                              fontSize: "16px",
                              color: "#EA2C54",
                              marginLeft: "4px",
                              paddingTop: "0px",
                            }}
                          ></span>
                        </OverlayTrigger>
                      </FormB.Label>
                      <FormB.Control
                        name="password"
                        className={
                          form.touched.password && form.errors.password
                            ? "errorValidation"
                            : ""
                        }
                        capitalize={true}
                        type={typePassword}
                        placeholder="*************"
                        required={true}
                        disabled={inputDisabled}
                        value={form.values.password}
                        onChange={(e) =>
                          form.setFieldValue("password", e.target.value)
                        }
                        onBlur={() => form.setFieldTouched("password", true)}
                      />
                      {form.errors.password && (
                        <FormB.Text className="text-muted error">
                          {form.errors.password}
                        </FormB.Text>
                      )}
                    </FormB.Group>
                  </Col>
                  <Col md={1}>
                    <img
                      id="type_password"
                      src={
                        typePassword == "password"
                          ? `${process.env.PUBLIC_URL}/img/eye.svg`
                          : `${process.env.PUBLIC_URL}"/img/eye-slash.svg`
                      }
                      onClick={showHide}
                      style={{ cursor: "pointer", marginTop: "46px" }}
                      disabled={inputDisabled}
                    />
                  </Col>
                </Row>
                {/* Checked */}
                {permissions.admin_users.edit && (
                  <Row>
                    <Col className="mt-2">
                      <FormCheck
                        name="test_account"
                        label={t("modalCreateRelative:labels.testAccount")}
                        type="checkbox"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                        disabled={inputDisabled}
                      />
                    </Col>
                  </Row>
                )}
                {/* dividing line */}
                <Row>
                  <Col
                    style={{
                      backgroundColor: "#d3d3d3",
                      height: 2,
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  ></Col>
                </Row>
                {/* Title Billing information */}
                <Row>
                  <Col
                    style={{
                      color: "#000000",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("modalCreateRelative:labels.billingInformation")}
                  </Col>
                </Row>
                {/* Name or Social reason */}
                <Row>
                  <Col md={12}>
                    <FieldInput
                      label={t("modalCreateRelative:labels.businessName")}
                      capitalize={true}
                      name="receiver_name"
                      type="text"
                      placeholder={t(
                        "modalCreateRelative:labels.placeholderBusinessName"
                      )}
                      disabled={inputDisabled}
                    />
                  </Col>
                </Row>
                {/* RFC & Postal Code */}
                <Row>
                  <Col md={7}>
                    <FieldInput
                      label={t("modalCreateRelative:labels.rfc")}
                      capitalize={true}
                      name="rfc_receiver"
                      type="text"
                      placeholder={t(
                        "modalCreateRelative:labels.placeholderRfc"
                      )}
                      disabled={inputDisabled}
                    />
                  </Col>
                  <Col md={4}>
                    <FieldInput
                      label={t("modalCreateRelative:labels.zipCode")}
                      capitalize={true}
                      name="zip_code"
                      type="text"
                      placeholder="01000"
                      showRequired={true}
                      disabled={inputDisabled}
                    />
                  </Col>
                </Row>
                {/* Tax Regime */}
                <Row>
                  <Col md={12}>
                    <FormSelect
                      label={t("modalCreateRelative:labels.taxRegime")}
                      placeholder={t(
                        "modalCreateRelative:labels.placeholderTaxRegime"
                      )}
                      name="regimen_selected"
                      options={regimenRfc}
                      onChange={(e) => {
                        addRfc(e);
                        form.setFieldValue("regimen_selected", e);
                      }}
                      formatOptionLabel={function (data) {
                        return (
                          <span
                            dangerouslySetInnerHTML={{ __html: data.label }}
                          />
                        );
                      }}
                      isDisabled={inputDisabled}
                    />
                  </Col>
                </Row>
                {/**Show errors when creating a prospect*/}
                {error && (
                  <div className="mtop20">
                    <Alert variant="warning">{error}</Alert>
                  </div>
                )}
              </ModalBody>
              <Modal.Footer>
                <Button
                  variant="light"
                  onClick={closeModal}
                  disabled={disabledCreateRelative}
                >
                  {t("modalCreateRelative:buttons.cancel")}
                </Button>
                <Button type="submit" disabled={disabledCreateRelative}>
                  {disabledCreateRelative ? (
                    <TextLoading
                      text={t("buttons.creatingRelative")}
                      variant="white"
                    />
                  ) : (
                    <> {t("buttons.createRelative")}</>
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {showModalCreateStudent && (
        <CreateStudentModal
          showModalCreateStudent={showModalCreateStudent}
          setShowModalCreateStudent={setShowModalCreateStudent}
          studentCreated={studentCreated}
        />
      )}
    </div>
  );
}

ModalCreateRelative.propTypes = {
  showModalCreateReleative: PropTypes.bool,
  setShowModalCreateReleative: PropTypes.func,
  language: PropTypes.string,
  updateList: PropTypes.func,
};
