// Import necessary libaries
import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Card, Row, Col, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Custom Components
import selectStyles from "../../global/GlobalTools";
import TextLoading from "../../global/TextLoading";
import {
  CreateMainGradingCriteria,
  GetMainGradingCriteria,
} from "../../../api/class";
import ModalSuccess from "../../global/modals/ModalSuccess";

export default function ModalGradingCriteria({
  openButtonStyle = {},
  schoolLevels = [],
  isLoading = false,
}) {
  const [t] = useTranslation(["global", "class"]);
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [grandingCriterias, setGrandingCriterias] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);
  const [indexTemp, setIndexTemp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState(0);

  /**
   * Puporse: Select the school level
   * @param {obj} level
   */
  const selectLevel = (level) => {
    setSelectedLevel(level);
    getGradingCriterias(level.value);
  };

  /**
   * Purpose: Close the modal and reset the state values
   */
  const handleOnclose = () => {
    setSelectedLevel(null);
    setError(false);
    setGrandingCriterias([]);
    setOpen(false);
  };

  /**
   * Purpose: Add a new grading criteria
   */
  const addGradingCriteria = () => {
    // Get only evaluables grading criterias
    const criteriasEvaluables = grandingCriterias.filter(
      (criteria) => criteria?.evaluable === "1"
    );
    // Add new criteria
    const newCriterias = [
      ...grandingCriterias,
      {
        name: "",
        evaluable: "1",
        percentage: criteriasEvaluables?.length ? 0 : 100,
      },
    ];
    // Set the new grading criteria
    setGrandingCriterias(newCriterias);
  };

  /**
   * Purpose: Verify if grading criteria has id
   * @param {number} index
   */
  const checkRemoveCriteria = (index) => {
    const criteriaToRemove = grandingCriterias[index];
    if (criteriaToRemove?.grading_criterion_id) {
      setIndexTemp(index);
      setOpen(false);
      setShowDeleteModal(true);
    } else {
      removeLocalGradingCriteria(index);
    }
  };

  /**
   * Purpose: Remove a local grading criteria
   * @param {number} index
   */
  const removeLocalGradingCriteria = (index) => {
    const newCriterias = [...grandingCriterias];
    newCriterias.splice(index, 1);
    setGrandingCriterias(newCriterias);
  };

  /**
   * Purpose: Remove an existing grading criteria
   */
  const removeGradingCriteria = () => {
    // Get the criterion to remove and check if it is evaluable
    const criteriaToRemove = grandingCriterias[indexTemp];
    const isEvaluable = criteriaToRemove.evaluable === "1";

    // Create a copy of the current criteria and remove the selected criterion
    const currentCriterias = [...grandingCriterias];
    currentCriterias.splice(indexTemp, 1);

    // If the removed criterion is evaluable, recalculate the percentages
    if (isEvaluable) {
      // Filter evaluable criteria and calculate the new percentage
      const evaluableCriterias = currentCriterias.filter(
        (criteria) => criteria?.evaluable === "1"
      );
      const totalCriteriasEvaluables = evaluableCriterias?.length || 1;
      const equalPercentage = 100 / totalCriteriasEvaluables;

      // Assign the new percentage to the evaluable criteria
      const newCriterias = currentCriterias.map((criteria) => {
        const isEvaluable = criteria?.evaluable === "1";
        return {
          ...criteria,
          percentage: isEvaluable ? equalPercentage : 0,
        };
      });

      // Save the criteria with recalculated percentages
      saveGradingCriteria(newCriterias);
    } else {
      // Save the criteria without changes to the percentages
      saveGradingCriteria(currentCriterias);
    }
    // Close the confirmation modal for the deletion
    setShowDeleteModal(false);
  };

  /**
   * Purpose: Check grading criterias
   */
  useEffect(() => {
    // Calculate total percentage
    const totalPercentage = grandingCriterias.reduce(
      (acc, { percentage }) => acc + (Number(percentage) || 0),
      0
    );
    // Save the sum of the percentages
    setTotalPercentage(totalPercentage);
  }, [grandingCriterias]);

  /**
   * Purpose: Save the grading criteria
   * @param {number} levelId
   */
  const getGradingCriterias = (levelId) => {
    setLoading(true);
    const request = {
      organization_school_level_cycle_id: levelId,
    };
    GetMainGradingCriteria(request).then((response) => {
      if (response?.data) {
        setGrandingCriterias(response.data);
      } else {
        setError(response?.description);
      }
      setLoading(false);
    });
  };

  /**
   * Description: Function to check if all grading criterias are valid
   * @param {array} [gradingCriterias=[]]
   * @return {boolean}
   */
  const validateForm = (gradingCriterias = []) => {
    let isValid = true;
    const newCriterias = [];
    // Validate criterias
    for (const criteria of gradingCriterias) {
      const isValidPercentage = Number(criteria?.percentage || 0) > 0;
      const isEvaluable = criteria?.evaluable === "1";
      const isCorrectName = criteria?.name?.length;
      const isCorrectPercentage =
        (isValidPercentage && isEvaluable) ||
        (!isValidPercentage && !isEvaluable);
      // Set criterias validated
      criteria.invalidName = !isCorrectName;
      criteria.invalidPecentage = !isCorrectPercentage;
      newCriterias.push(criteria);
    }
    setGrandingCriterias(newCriterias);
    isValid = gradingCriterias.every(
      (criteria) => !criteria.invalidName && !criteria.invalidPecentage
    );
    return isValid;
  };

  /**
   * Purpose: Save the grading criteria
   */
  const saveGradingCriteria = (grading_criteria = grandingCriterias) => {
    if (!validateForm(grading_criteria)) {
      return;
    }
    setIsSaving(true);
    setError(false);
    const request = {
      organization_school_level_cycle_id: selectedLevel.value,
      grading_criteria,
    };
    CreateMainGradingCriteria(request).then((response) => {
      if (response?.data) {
        setGrandingCriterias(response.data);
        handleOnclose();
      } else {
        setError(response?.description);
      }
      setIsSaving(false);
    });
  };

  /**
   * Purpose: skeletong loading criteria list
   * @return {JSX}
   */
  const lazyLoadingCriterias = () => {
    return (
      <>
        <div
          className="skeleton-loading mb-3"
          style={{ height: "36px", width: "100%", backgroundColor: "#e0e0e0" }}
        />
        {Array.from({ length: 2 }).map((_, index) => (
          <Card
            className="mb-3"
            key={index}
            style={{ height: "160px", backgroundColor: "#F6F6F6" }}
          >
            <Card.Body>
              <Row>
                {/** Criteria level skeleton */}
                <Col xs={12} md={6}>
                  <div
                    className="skeleton-loading"
                    style={{
                      height: "40px",
                      backgroundColor: "#e0e0e0",
                      marginBottom: "20px",
                    }}
                  />
                </Col>
                {/** Criteria switch skeleton */}
                <Col xs={12} md={6}>
                  <div
                    className="skeleton-loading"
                    style={{
                      height: "40px",
                      backgroundColor: "#e0e0e0",
                      marginBottom: "20px",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                {/** Criteria name skeleton */}
                <Col xs={12} md={6}>
                  <div
                    className="skeleton-loading"
                    style={{
                      height: "50px",
                      backgroundColor: "#e0e0e0",
                      marginBottom: "10px",
                    }}
                  />
                </Col>
                {/** Criteria percentage skeleton */}
                <Col xs={12} md={6}>
                  <div
                    className="skeleton-loading"
                    style={{
                      height: "50px",
                      backgroundColor: "#e0e0e0",
                      marginBottom: "10px",
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </>
    );
  };

  /**
   * Purpose: Render the modal with the grading criteria form
   */
  return (
    <>
      {/**Open button dialog */}
      <Button
        variant="outline-secondary"
        style={{ ...openButtonStyle }}
        onClick={() => setOpen(true)}
        disabled={isLoading}
      >
        {t("class:modalGrandingCriteria.title")}
      </Button>
      {/** Grading Criteria Modal */}
      {open && (
        <Modal show={open} bsSize="large" size="lg">
          {/**Modal header */}
          <Modal.Header className="modal-header">
            {/**Modal title */}
            <Modal.Title className="modal-title">
              {t("class:modalGrandingCriteria.title")}
            </Modal.Title>
            {/**Add grading criterial button */}
            <Button
              style={{ float: "right" }}
              variant="secondary"
              onClick={addGradingCriteria}
              disabled={!selectedLevel || isSaving}
            >
              <i className="bi bi-plus"></i>
              {t("class:modalGrandingCriteria.addCriteria")}
            </Button>
          </Modal.Header>
          {/**Modal body */}
          <Modal.Body>
            {/**School level select */}
            <Form.Group className="mb-3">
              <Form.Label>
                {t("class:modalGrandingCriteria.schoolLevelLabel")}
              </Form.Label>
              <Select
                classNamePrefix={"select"}
                styles={selectStyles}
                options={schoolLevels}
                noOptionsMessage={() => t("select.noMatches")}
                loadingMessage={() => t("select.searching")}
                isDisabled={!schoolLevels.length || isSaving}
                onChange={selectLevel}
                placeholder={t("class:modalGrandingCriteria.schoolLevelLabel")}
              />
            </Form.Group>

            {loading ? (
              lazyLoadingCriterias()
            ) : (
              <>
                {/**Grading criteria description */}
                {grandingCriterias.length > 0 && (
                  <p className="mb-3">
                    {t("class:modalGrandingCriteria.criteriaDescription")}
                  </p>
                )}
                {/**Grading criteria form */}
                {grandingCriterias.map((criteria, index) => (
                  <Card
                    className="mb-3"
                    key={index}
                    style={{ backgroundColor: "#F6F6F6" }}
                  >
                    <Card.Body>
                      <Row>
                        {/**Criteria level */}
                        <Col xs={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {t("class:modalGrandingCriteria.criteriaLevel")}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        {/**Criteria switch */}
                        <Col xs={12} md={6}>
                          <Form.Switch
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <span style={{ marginRight: "8px" }}>
                              {t(
                                "class:modalGrandingCriteria.formCriteriaSwitch"
                              )}
                            </span>
                            <Form.Check
                              type="switch"
                              id={`switch-${index}`}
                              style={{ cursor: "pointer" }}
                              checked={
                                grandingCriterias[index].evaluable === "1"
                              }
                              onChange={(e) => {
                                const newCriterias = [...grandingCriterias];
                                newCriterias[index].evaluable = e.target.checked
                                  ? "1"
                                  : "0";
                                newCriterias[index].percentage = 0;
                                newCriterias[index].invalidPecentage =
                                  e.target.checked;
                                setGrandingCriterias(newCriterias);
                              }}
                              disabled={isSaving}
                            />
                          </Form.Switch>
                        </Col>
                      </Row>
                      <Row>
                        {/**Criteria name */}
                        <Col xs={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {t(
                                "class:modalGrandingCriteria.grandingCriteriaLabel"
                              )}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={
                                criteria.invalidName
                                  ? "errorValidation"
                                  : "well"
                              }
                              placeholder={t(
                                "class:modalGrandingCriteria.grandingCriteriaLabel"
                              )}
                              value={criteria.name}
                              maxLength={30}
                              onChange={(e) => {
                                const newCriterias = [...grandingCriterias];
                                newCriterias[index].name = e.target.value;
                                newCriterias[index].invalidName =
                                  e.target.value === "";
                                setGrandingCriterias(newCriterias);
                              }}
                              disabled={isSaving}
                            />
                            {criteria.invalidName && (
                              <Form.Text className="text-muted error">
                                {t("class:modalGrandingCriteria.nameRequired")}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                        {/**Criteria percentage */}
                        <Col xs={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {t(
                                "class:modalGrandingCriteria.grandingCriteriapercentage"
                              )}
                            </Form.Label>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Form.Control
                                type="number"
                                placeholder={t(
                                  "class:modalGrandingCriteria.grandingCriteriapercentage"
                                )}
                                className={
                                  criteria.invalidPecentage
                                    ? "errorValidation"
                                    : "well"
                                }
                                value={criteria.percentage}
                                onChange={(e) => {
                                  const newCriterias = [...grandingCriterias];
                                  const value = Number(e.target.value || 0);
                                  const positive = Math.max(0, value);
                                  newCriterias[index].percentage =
                                    positive || "";
                                  newCriterias[index].invalidPecentage =
                                    positive === 0;
                                  setGrandingCriterias(newCriterias);
                                }}
                                disabled={isSaving || criteria.evaluable != "1"}
                              />

                              <i
                                className="bi bi-dash-circle-fill fa-lg"
                                style={{ marginLeft: "8px" }}
                                role="button"
                                onClick={() => checkRemoveCriteria(index)}
                              />
                            </div>
                            {criteria.invalidPecentage && (
                              <Form.Text className="text-muted error">
                                {t(
                                  "class:modalGrandingCriteria.percentageRequired"
                                )}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
                {/**Grading criteria total percentage */}
                {grandingCriterias.length > 0 && (
                  <p
                    className="mt-4 mb-3"
                    style={{
                      color: totalPercentage == 100 ? "#10B981" : "#E61414",
                      fontWeight: "bold",
                    }}
                  >
                    {t("class:modalGrandingCriteria.sumTotalPercentage", {
                      percentage: totalPercentage,
                    })}
                  </p>
                )}
              </>
            )}
            {/**Errors */}
            {error && (
              <Row>
                <Col md={12}>
                  <Alert variant="warning">{error}</Alert>
                </Col>
              </Row>
            )}
          </Modal.Body>
          {/**Modal footer */}
          <Modal.Footer>
            {/**Close and save buttons */}
            <Button
              variant="secondary"
              onClick={handleOnclose}
              disabled={isSaving}
            >
              {t("global:buttons.cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => saveGradingCriteria()}
              disabled={totalPercentage !== 100 || isSaving}
            >
              {isSaving ? (
                <TextLoading
                  text={t("global:buttons.saving")}
                  variant="light"
                />
              ) : (
                t("global:buttons.save")
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/** Delete Grading Criteria Modal */}
      {showDeleteModal && (
        <ModalSuccess
          showModalSuccess={showDeleteModal}
          title={t("class:modalGrandingCriteria.deleteTitle")}
          message={t("class:modalGrandingCriteria.deleteDescription")}
          fnCancelButton={() => {
            setShowDeleteModal(false);
            setOpen(true);
          }}
          loading={isSaving}
          txtBtnAccept={t("global:buttons.eliminate")}
          fnAcceptButton={removeGradingCriteria}
        />
      )}
    </>
  );
}

ModalGradingCriteria.propTypes = {
  openButtonStyle: PropTypes.object,
  schoolLevels: PropTypes.array,
  isLoading: PropTypes.bool,
};
