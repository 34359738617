// Import Libraries
import React, { useState, useRef, useEffect, useContext } from "react";
import { Button, Col, Container, Row, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ExcellentExport from "excellentexport";
import ReactToPrint from "react-to-print";
import AsyncSelect from "react-select/async";
import * as moment from "moment";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
// Import Custom Components
import ContentTable from "../table/ContentTable";
import SchoolCycle from "../../components/global/form/SchoolCycle";
import {
  getUrlParamValue,
  goToHref,
  GetUserRestriction,
  validateUserRoleIds,
} from "../../components/global/GlobalTools";
import ContentSolid from "../global/ContentSolid";
import { LoadingTable } from "../lazyLoading/LazyLoading";
import { Table } from "../table/Table";
import SelectFilter from "../table/SelectFilter";
import ModalImportSchedule from "./modals/ModalImportSchedule";
import ModalDetailsSchedule from "./modals/ModalDetailsSchedule";
import ModalSuccessImport from "./modals/ModalSuccessImport";
import ModalConfirmDeleted from "./modals/ModalConfirmDeleted.js";
import { MainContext } from "../../App";

// Import API Services
import { getStudentsReduced } from "../../api/class";
import { getClassList } from "../../api/class";
import { importClassesCSV } from "../../api/class";
import { GetStudentsParent } from "../../api/class";
import ModalSuccess from "../global/modals/ModalSuccess";

/**Get param to open ImportSchedule Modal */
const importSchedule = getUrlParamValue("importSchedule");
//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

//Record reporting options
const actionOptions = [
  { value: "list", label: "superAdmin:view.downloadPDF" },
  { value: "excel", label: "superAdmin:view.downloadExcel" },
];
const prefix = process.env.REACT_APP_PREFIX;

// Save cycle
const obtainFilterCycle = () => {
  let cycle = null;
  // Get the last saved cycle
  let cycleStringify = localStorage.getItem("filterCycleClass");
  if (cycleStringify) {
    // parse the last cycle
    let cycleClass = cycleStringify && JSON.parse(cycleStringify);
    cycle = cycleClass;
    // clean the filter in local storage
    localStorage.removeItem("filterCycleClass");
  }
  return cycle;
};

const ClassView = () => {
  const history = useHistory();
  const componentRef = useRef(); //Create the reference for printing
  const [t] = useTranslation(["global", "superAdmin", "reports", "class"]);
  const [language] = useState(localStorage.getItem(`cmLanguage${prefix}`));
  const [printExport, setPrintExport] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [loadingView, setLoadingView] = useState(true);
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  let [typingCallback, setTypingCallback] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [readyLanguages, setReadyLanguages] = useState(false);
  const [count, setCount] = useState(0);
  const [studenSelected, setStudenSelected] = useState(null);
  const [studentsByParent, setStudentsByParent] = useState([]);
  //import values
  const [showImportSchedule, setShowImportSchedule] = useState(importSchedule);
  const [showDetailsSchedule, setShowDetailsSchedule] = useState(false);
  const [loadingImportClasses, setLoadingImportClasses] = useState(false);
  const [showModalSuccessImport, setShowModalSuccessImport] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [showModalConfirmDeleted, setShowModalConfirmDeleted] = useState(false);
  const [cycleSelected, setCycleSelected] = useState(null);
  const [showEditColumns, setShowEditColumns] = useState(false);
  const { permissions } = useContext(MainContext);
  const [openModalSuccess, setOpenModalSucces] = useState(false);
  const [modalSuccessTtile, setModalSuccessTitle] = useState("");
  const [modalSuccessMessage, setModalSuccessMessage] = useState("");
  const [txtBtnAccept, setTxtBtnAccept] = useState("");
  const [txtBtnCancel, setTxtBtnCancel] = useState("");
  const [hasDebtRestriction, setHasDebtRestriction] = useState(false);
  const [idStudentClass, setIdStudentClass] = useState(
    getUrlParamValue("studentId")
  );
  const [idCycleStudent, setIdCycleStudent] = useState(
    getUrlParamValue("cycleId")
  );
  const [filterCycle, setFilterCycle] = useState(obtainFilterCycle);
  const [filters, setFilters] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [errorModal, setErrorModal] = useState("");

  //table columns
  const columns = React.useMemo(
    () => [
      {
        Header: t("class:table.classTitle"),
        accessor: "class_title",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
      },
      {
        Header: t("class:table.subject"),
        accessor: "subject_name",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Filter: (props) => <SelectFilter {...props} />,
      },
      {
        Header: t("class:table.teacherAssigned"),
        accessor: "teacher_name",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
      },
      {
        Header: t("class:table.schooling"),
        accessor: "school_level_name",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Filter: (props) => <SelectFilter {...props} />,
      },
      {
        Header: t("class:table.grade"),
        accessor: "grade_level",
        minWidth: 100,
        width: 150,
        maxWidth: 200,
        Filter: (props) => <SelectFilter {...props} />,
      },
      {
        Header: t("class:table.group"),
        accessor: "grade_group",
        minWidth: 100,
        width: 150,
        maxWidth: 200,
        Filter: (props) => <SelectFilter {...props} />,
      },
      {
        Header: t("class:table.totalStudents"),
        accessor: "students_number",
        minWidth: classes.length ? 120 : 150,
        width: 170,
        maxWidth: 200,
      },
      {
        Header: t("class:table.classDays"),
        accessor: "classDays",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Filter: (props) => <SelectFilter {...props} />,
        Cell: ({ cell: { value } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {value.map((schedule, i) => {
                return (
                  <div key={i}>
                    {schedule.map((day, index) => {
                      return <p key={index}>{day}</p>;
                    })}
                  </div>
                );
              })}
            </td>
          );
        },
      },
      {
        Header: t("class:table.startTime"),
        accessor: "startTime",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Filter: (props) => <SelectFilter {...props} />,
        Cell: ({ cell: { value } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {value.map((schedule, i) => {
                return (
                  <div key={i}>
                    {schedule.map((start, index) => {
                      return <p key={index}>{start}</p>;
                    })}
                  </div>
                );
              })}
            </td>
          );
        },
      },
      {
        Header: t("class:table.endTime"),
        accessor: "endTime",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Filter: (props) => <SelectFilter {...props} />,
        Cell: ({ cell: { value } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {value.map((schedule, i) => {
                return (
                  <div key={i}>
                    {schedule.map((end, index) => {
                      return <p key={index}>{end}</p>;
                    })}
                  </div>
                );
              })}
            </td>
          );
        },
      },

      {
        Header: t("class:table.average"),
        accessor: "score",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
      },
    ],
    [loadingView]
  );

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => classes, [loadingView]);

  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("class:main.classReport"),
  };

  // Export to Excel
  const downloadFile = (exportOptions, exportData) => {
    let copyExportData = [].concat(exportData[0].from.array);
    // Eliminate dependency on subobjects
    let shceduls = [];
    for (let item of copyExportData) {
      let obj = JSON.parse(JSON.stringify(item));
      shceduls.push(obj);
    }
    for (let pos in shceduls) {
      if (pos != 0) {
        let scheduls = shceduls[pos][7].length;
        // Flatten and list class schedules in a string
        if (scheduls == 1) {
          // Case in which there is only one schedule
          shceduls[pos][7] = shceduls[pos][7][0].toString();
          shceduls[pos][8] = shceduls[pos][8][0].toString();
          shceduls[pos][9] = shceduls[pos][9][0].toString();
        } else {
          // Case in which there is more than one schedule
          let groupsDays = [].concat(shceduls[pos][7]);
          let startTimes = [].concat(shceduls[pos][8]);
          let endTimes = [].concat(shceduls[pos][9]);

          let numberDay = 1;
          let allDays = "";
          let allStart = "";
          let allEnd = "";

          for (let group of groupsDays) {
            // Day
            let daysString = "(" + numberDay + ")" + group.toString() + "  ";
            // StartTime
            let startString =
              "(" +
              numberDay +
              ")" +
              startTimes[numberDay - 1][0].toString() +
              "  ";
            // EndTime
            let endString =
              "(" +
              numberDay +
              ")" +
              endTimes[numberDay - 1][0].toString() +
              "  ";
            // Group the values of each array
            allDays = allDays + daysString;
            allStart = allStart + startString;
            allEnd = allEnd + endString;
            numberDay++;
          }
          shceduls[pos][7] = allDays;
          shceduls[pos][8] = allStart;
          shceduls[pos][9] = allEnd;
        }
      }
    }
    //Object to export the data
    let data = [
      {
        name: language == "spanish" ? "Reporte" : "Report", // Sheet name
        from: {
          array: shceduls, // array of arrays
        },
      },
    ];
    ExcellentExport.convert(exportOptions, data);
  };

  /**
   *gets students by text search
   * @param {String} inputValue
   * @param {function} callback
   */
  const searchStudents = (inputValue, callback) => {
    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    typingCallback = setTimeout(() => {
      getStudentsReduced({ search: inputValue, status: "Active" }).then(
        (result) => {
          const studentsFormat = result.data.data.map((item) => {
            return {
              label: `${item.first_name} ${item.middle_name} ${item.last_name}`,
              value: item.id,
            };
          });
          callback(studentsFormat);
        }
      );
    }, 1000);
    setTypingCallback(typingCallback);
  };

  /**
   * Redirects to CMV1
   * @param {String} tabView
   */
  const goApp1 = (tabView) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/${tabView}`);
  };

  /**
   * Obtain complete information of the selected cycle
   * @param {int} cycleId
   */
  const getCycleSelectedInfo = (cycleId) => {
    const cycles = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    const hasCycles = cycles && Array.isArray(cycles) && cycles.length;
    if (hasCycles) {
      const cycleSelected = cycles.find((cycle) => cycle.id == cycleId);
      setFilterCycle(cycleSelected);
    } else {
      setTimeout(() => {
        getCycleSelectedInfo();
      }, 1000);
    }
  };

  /**
   * Get the list of classes
   * @param {int} cycleId
   */
  const getClasses = (cycleId, studentId) => {
    setLoadingView(true);
    setLoading(true);
    let request = {
      school_cycle_id: cycleId || cycleSelected,
      // Set timezone
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if ((studentId || studenSelected) && studentId != "clear") {
      request.student_id = studentId || studenSelected.value;
    }
    cycleId && getCycleSelectedInfo(cycleId);
    getClassList(request)
      .then((result) => {
        let classes =
          result && result.data && result.data.data ? result.data.data : [];
        classes.forEach((element) => {
          element.index = element.id;
          element.linkToPage = `${process.env.REACT_APP_V1}/class/${element.id}?fromTableView=true`;
          element.school_level_name =
            element.school_level_name || element.program_name;
          element.grade_level = element.grade_level || element.program_level;
          let schedules = element.schedule;
          const daysAndTimes = formatDays(schedules);
          element.classDays = daysAndTimes.classDays;
          element.startTime = daysAndTimes.startTime;
          element.endTime = daysAndTimes.endTime;
          for (let param in element) {
            element[param] = element[param] || "N/A";
          }
        });
        setClasses(classes);
        setCount(classes.length);
      })
      .catch()
      .finally(() => {
        setLoadingView(false);
        setLoading(false);
      });
  };

  /**
   * Extracting and formatting class days and times
   * @param {obj} schedule
   * @param {string} field
   * @returns
   */
  const formatDays = (schedule) => {
    let daysSchedul = [];
    let totalDaysClass = 0;
    if (schedule) {
      for (let item of schedule) {
        let startTimeFormat = moment.unix(item.start).local().format("hh:mm A");
        let endTimeFormat = moment.unix(item.end).local().format("hh:mm A");
        const daysWeek = listDays(item);
        totalDaysClass = totalDaysClass + daysWeek.length;
        daysSchedul.push({
          start: startTimeFormat,
          end: endTimeFormat,
          days: daysWeek,
        });
      }
    }

    return {
      classDays: daysSchedul.map((item) => item.days),
      startTime: daysSchedul.map((item) => {
        let listStart = [];
        item.days.forEach(() => {
          listStart.push(item.start);
        });
        return listStart;
      }),
      endTime: daysSchedul.map((item) => {
        let listEnd = [];
        item.days.forEach(() => {
          listEnd.push(item.end);
        });
        return listEnd;
      }),
    };
  };

  /**
   * Generates a list of days on which the class is enabled
   * @param {obj} item
   * @returns
   */
  const listDays = (item) => {
    let days = [];
    item.monday ? days.push("L") : null;
    item.tuesday ? days.push("M") : null;
    item.wednesday ? days.push("Mi") : null;
    item.thursday ? days.push("J") : null;
    item.friday ? days.push("V") : null;
    item.saturday ? days.push("S") : null;
    item.sunday ? days.push("D") : null;
    return days;
  };

  /**
   * Purpose: Redirects to APP V1 and stores the filters and hidden columns of the table
   * @param {Object} class //Contains class data
   */
  const profileClass = (dataClass) => {
    goToHref(`/class/${dataClass.index}?fromTableView=true`);
  };

  /**
   * Method to send to import the classes
   */
  const sendImportSchedules = () => {
    setLoadingImportClasses(true);
    importClassesCSV(schedule)
      .then((result) => {
        if (result.status) {
          setShowDetailsSchedule(false);
          setShowModalSuccessImport(true);
        } else {
          setErrorModal(result?.description);
        }
      })
      .finally(() => {
        setLoadingImportClasses(false);
      });
  };

  /**
   * Gets and sets the selected student to filter classes
   * @param {obj} student
   * @param {int} cycleId
   */
  const selectStudent = (student, cycleId) => {
    if (student && !student.hasDebtRestriction) {
      setHasDebtRestriction(false);
      getClasses(cycleId, student.value);
      setStudenSelected(student);
    } else if (
      student &&
      student.hasDebtRestriction &&
      validateUserRoleIds([6, 8])
    ) {
      showRestrictions();
    } else {
      setHasDebtRestriction(false);
      setStudenSelected(null);
      getClasses(cycleId, "clear");
    }
  };

  /**
   * get all students of the parent
   * @param {int} cycleId
   */
  const getStudentsParents = (cycleId) => {
    GetStudentsParent().then((result) => {
      const students = result.data;
      const studentsFormat = students.map((item) => {
        return {
          label: item.first_name,
          value: item.id,
          hasDebtRestriction:
            item &&
            item.has_debt_restriction &&
            item.has_debt_restriction == "partial_restriction" //total_restriction Not here
              ? true
              : false,
        };
      });
      if (students.length) {
        let studentProp = false;
        if (idStudentClass) {
          studentProp = studentsFormat.find(
            (student) => student.value == idStudentClass
          );
        }
        selectStudent(
          studentProp || studentsFormat[0],
          idCycleStudent || cycleId
        );
        setStudentsByParent(studentsFormat);
        setIdStudentClass(false);
        setIdCycleStudent(false);
      }
    });
  };

  /**
   * Purpose: Function to get classes by user group
   * @param {obj} cycle
   */
  const getClassesByGroup = (cycle) => {
    let schoolCycleId = cycle && cycle.id ? cycle.id : cycle;
    if (validateUserRoleIds([6])) {
      getStudentsParents(schoolCycleId);
    } else if (validateUserRoleIds([8])) {
      // TODO: -> Get Restraction value from user session
      let hasDebtRestriction = GetUserRestriction();
      // Validate restriction
      hasDebtRestriction =
        hasDebtRestriction &&
        typeof hasDebtRestriction == "string" &&
        hasDebtRestriction !== "false"
          ? true
          : false;
      if (hasDebtRestriction) {
        showRestrictions();
      } else {
        getClasses(schoolCycleId, Cookies.get(`cmStudentId${prefix}`));
      }
    } else {
      getClasses(schoolCycleId);
    }
  };

  /**
   * Purpose: Action when you click on a table row
   * @param {Obj} dataClass // It is an object with the parameters of the classes
   * @param {Object} state // Contains the data of the table in general
   */
  const rowOnclick = (dataClass, state) => {
    let filters = state.filters; // Get the filters of the table
    let hiddenColumns = state.hiddenColumns; // Get the hidden columns of the table
    localStorage.setItem("filtersClass", JSON.stringify(filters)); // Save the filters in the table
    localStorage.setItem("filterCycleClass", JSON.stringify(filterCycle)); //save the current cycle in the local storage
    localStorage.setItem("hiddenColumnsClass", JSON.stringify(hiddenColumns)); // Save the hidden columns in the cookie
    validateUserRoleIds([6, 8], null, "&&", true)
      ? profileClass(dataClass)
      : null;
  };

  /**
   * Description: Effect to know if all prospects are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  // Effect for loading initial translations
  useEffect(() => {
    if (typeof t == "function") {
      setTimeout(() => {
        setReadyLanguages(true);
      }, 500);
    }
  }, [t]);

  /**
   * Puporse: Displays the restriction modal
   */
  const showRestrictions = () => {
    setHasDebtRestriction(true);
    setTimeout(() => {
      setLoadingView(false);
      setLoading(false);
      setModalSuccessMessage(t("modalAlertClassesRestrictions.message"));
      setModalSuccessTitle(t("modalAlertClassesRestrictions.title"));
      setTxtBtnAccept(t("reports:paymentsTabs.statementAccount"));
      setTxtBtnCancel(t("buttons.close"));
      setOpenModalSucces(true);
    }, 1000);
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    let dataFilter = JSON.parse(localStorage.getItem("filtersClass"));
    let dataHiddenColumns = JSON.parse(
      localStorage.getItem("hiddenColumnsClass")
    );
    if (dataFilter) {
      setFilters(dataFilter);
      localStorage.removeItem("filtersClass");
    }
    if (dataHiddenColumns) {
      setHiddenColumns(dataHiddenColumns);
      localStorage.removeItem("hiddenColumnsClass");
    }
  }, []);

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col md={2}>
          {permissions.classes.access && (
            <SchoolCycle
              useIn="filters"
              selectedByDefault={true}
              getDefaultValues={(cycle) => {
                getClassesByGroup(cycle);
                setCycleSelected(cycle.id || null);
              }}
              handleOnchange={(e) => {
                setCycleSelected(e?.id || null);
                getClasses(e?.id || null); //e?.id It is a simplified ternary where it looks for id when e exists.
              }}
              selectedCycleId={idCycleStudent}
              value={filterCycle}
            />
          )}
        </Col>
        <Col className="offset-lg-7 offset-md-6" lg={3} md={4}>
          {validateUserRoleIds([8], null, "||", true) && (
            <AsyncSelect
              loadOptions={searchStudents}
              placeholder={t("class:main.searchStudent")}
              onChange={(student) => selectStudent(student)}
              isClearable
              defaultOptions={studentsByParent}
              value={studenSelected}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <a
            href={`${process.env.REACT_APP_V1}/class`}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="outline-secondary"
              onClick={() => goApp1("class")}
              disabled={hasDebtRestriction}
            >
              {t("class:main.calendarView")}
            </Button>
          </a>
          <Button
            variant="outline-secondary"
            className="ms-3"
            onClick={() => {
              let selectAll = {
                switch: !selectAllRows.switch,
                value: !selectAllRows.value,
              };
              setSelectAllRows(selectAll);
            }}
            disabled={hasDebtRestriction}
          >
            {readyLanguages
              ? t(
                  !isAllRowsSelected
                    ? "global:buttons.selectAll"
                    : "global:buttons.unselectAll"
                )
              : null}
          </Button>
          {selectedRowIds.length > 0 && permissions.classes.delete && (
            <Button
              variant="outline-secondary"
              className="ms-3"
              onClick={() => setShowModalConfirmDeleted(true)}
            >
              {t("class:main.delete")}
            </Button>
          )}
          {/* Total counter of records and selected records in Classes table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col md={6}>
          {permissions.classes.create && (
            <a
              href={`${process.env.REACT_APP_V1}/class/new`}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                className="float-end ms-3"
                onClick={() => goApp1("class/new")}
              >
                {t("class:main.createSchedule")}
              </Button>
            </a>
          )}
          {permissions.classes.create && (
            <a
              href="?importSchedule=true"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="outline-secondary"
                className="float-end ms-3"
                onClick={() => setShowImportSchedule(true)}
              >
                {t("class:main.importSchedule")}
              </Button>
            </a>
          )}
          <Dropdown className="float-end" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="dropdownReportCards"
              variant="outline-secondary"
              disabled={hasDebtRestriction}
            >
              <i className="bi bi-download"></i>
              {t("global:buttons.download")}
            </Dropdown.Toggle>
            <Dropdown.Menu disabled={printExport}>
              {actionOptions.map((action) => {
                return action.value == "list" ? (
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                    )}
                    content={() => componentRef.current}
                  />
                ) : (
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                    )}
                    content={() => downloadFile(exportOptions, exportData)}
                  />
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      {loading ? (
        <ContentSolid>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable className="mt-3" startColumFilter={1} lastColumFilter={4}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            setExportData={setExportData}
            setPrintExport={setPrintExport}
            rowSelect={true}
            setSelectedRowIds={setSelectedRowIds}
            setIsAllRowsSelected={setIsAllRowsSelected}
            selectAllRows={selectAllRows}
            limitRowsShown={50}
            rowOnclick={rowOnclick}
            showEditColumns={showEditColumns} //Flag for the modal "Edit List Columns"
            setShowEditColumns={setShowEditColumns} //Toggle function of the "Edit List Columns" modal
            showColumnGear={true}
            setRowsDisplayed={setCount}
            isLinked={true}
            filters={filters} // Contains the table filters
            hiddenColumns={hiddenColumns} // Contains the hidden columns of the table
          ></Table>
        </ContentTable>
      )}
      {showImportSchedule && (
        <ModalImportSchedule
          showImportSchedule={showImportSchedule}
          setShowImportSchedule={setShowImportSchedule}
          setShowDetailsSchedule={setShowDetailsSchedule}
          setScheduleDetails={setScheduleDetails}
          setScheduleMain={setSchedule}
        />
      )}

      {showDetailsSchedule && (
        <ModalDetailsSchedule
          showDetailsSchedule={showDetailsSchedule}
          setShowDetailsSchedule={setShowDetailsSchedule}
          setShowImportSchedule={setShowImportSchedule}
          sendImportSchedules={sendImportSchedules}
          scheduleDetails={scheduleDetails}
          loadingImportClasses={loadingImportClasses}
          error={errorModal}
          cleanErrors={() => setErrorModal("")}
        />
      )}

      {showModalSuccessImport && (
        <ModalSuccessImport
          showModalSuccessImport={showModalSuccessImport}
          setShowModalSuccessImport={setShowModalSuccessImport}
        />
      )}

      {showModalConfirmDeleted && (
        <ModalConfirmDeleted
          showModalConfirmDeleted={showModalConfirmDeleted}
          setShowModalConfirmDeleted={setShowModalConfirmDeleted}
          items={selectedRowIds}
          getClasses={getClasses}
        />
      )}
      {openModalSuccess && (
        <ModalSuccess
          showModalSuccess={openModalSuccess}
          size="md"
          title={modalSuccessTtile}
          message={modalSuccessMessage}
          txtBtnAccept={txtBtnAccept}
          fnAcceptButton={() => goToHref("/payments/accountStatus")}
          fnCancelButton={() => history.push("/dashboard")}
          txtBtnCancel={txtBtnCancel}
        />
      )}
    </Container>
  );
};
export default ClassView;
ClassView.propTypes = {
  cell: PropTypes.object,
};
